/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import TransactionSearch from "./components/TransactionSearch";
import TransactionList from "./components/TransactionList";
import { Box } from "@chakra-ui/react";
import moment from "moment";

interface Filters {
  [key: string]: any;
}

interface PayloadType {
  [key: string]: any;
}

const rowPerPage = 10;

const initFrom = moment().subtract(1, 'month');
const initTo = moment();
const initialDateRangeFilter = [initFrom.toDate(), initTo.toDate()];

const initialFilters: Filters = {
  from: initFrom,
  to: initTo,
  merchant_id: "",
  subaccount_id: "",
  payout_id: "",
  asset: "",
  status: "",
};

const Transaction = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);

  const [merchantIdToGetSubaccount, setMerchantIdToGetSubaccount] = useState<any>(null);
  const [subaccountIdToGetReceiver, setSubaccountIdToGetReceiver] = useState<any>(null);
  const [receiverIdToGetReceiverAccount, setReceiverIdToGetReceiverAccount] = useState<any>(null);

  const [merchantList, setMerchantList] = useState<any>(null);

  const [subaccountList, setSubaccountList] = useState<any>(null);
  const [isSubaccountListLoading, setIsSubaccountListLoading] = useState<boolean>(false);

  const [receiverList, setReceiverList] = useState<any>(null);
  const [isReceiverListLoading, setIsReceiverListLoading] = useState<boolean>(false);

  const [receiverAccountList, setReceiverAccountList] = useState<any>(null);
  const [isReceiverAccountListLoading, setIsReceiverAccountListLoading] = useState<boolean>(false);
  

  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDateRangeFilter);
  const subaccount_id = localStorage.getItem("subaccountId");
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      localStorage.removeItem("merchantIdToFilterTransaction");
      localStorage.removeItem("subaccountIdToFilterTransaction");
      localStorage.removeItem("receiverIdToFilterTransaction");
      localStorage.removeItem("receiverAccountIdToFilterTransaction");
      setIsLoading(true);      
      await fetchTransactions({ page: 0, limit: rowPerPage, ...filters });

      if (userRole === 'superadmin') {
        await fetchMerchants();
      }

      setIsLoading(false);
      setIsFilterLoading(false);
      setIsSubaccountListLoading(false);
    };

    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      fetchSubaccounts();
    };

    if (merchantIdToGetSubaccount) {
      fetchData();
    }
  }, [merchantIdToGetSubaccount]); // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      fetchReceivers();
    };

    if(subaccountIdToGetReceiver) {
      fetchData();
    }
  }, [subaccountIdToGetReceiver]); // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      fetchReceiverAccounts();
    };

    if(receiverIdToGetReceiverAccount) {
      fetchData();
    }
  }, [receiverIdToGetReceiverAccount]); // eslint-disable-line

  const fetchTransactions = async (payload: PayloadType = {}, isReset = false) => {    
    try {

      const formattedFromDate = moment(!isReset ? selectedDates[0] : initFrom, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00');
      const formattedToDate = moment(!isReset ? selectedDates[1] : initTo, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59');

      const queryObject: PayloadType = {
        limit: payload?.limit,
        page: payload?.page + 1,
        subaccount_id: subaccount_id,
        from: formattedFromDate,
        to: formattedToDate
      };

      const merchantId = localStorage.getItem("merchantIdToFilterTransaction");
      const subaccountId = localStorage.getItem("subaccountIdToFilterTransaction");

      if(payload?.status !== "" && payload?.status !== undefined) {
        queryObject.status = payload?.status
      }

      if(payload?.payout_id !== "" && payload?.payout_id !== undefined) {
        queryObject.payout_id = payload?.payout_id
      }

      if(userRole === 'superadmin') {
        delete queryObject.subaccount_id;

        if (merchantId && merchantId !== "" && merchantId !== undefined) {
          queryObject.merchant_id = merchantId
        }

        if (subaccountId !== "" && subaccountId !== undefined) {
          queryObject.subaccount_id = subaccountId
        }
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/api/transaction/get-all-transactions?${queryString}`
      );

      let newData = response.data.transactions;
      let totalTransactions = response.data.totalTransactions;

      if (newData && newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);
      setIsSubaccountListLoading(false);
      setRowCount(totalTransactions);
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setIsSubaccountListLoading(false);
      // Clear all
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      subaccount_id: subaccount_id,
      ...filters
    };

    // if select new row per page, reset page to 0, reset lastkey
    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        subaccount_id: subaccount_id,
        ...filters
      };
      setPage(0);
      setPageSize(newValue.pageSize);
    } else {
      setPage(newValue.page);
    }

    fetchTransactions(newFilters);
  };

  const handleSearchSubmit = async () => {
    setIsLoading(true);
    setIsFilterLoading(true);
    await fetchTransactions({ page: 0, limit: rowPerPage, ...filters });
    setIsFilterLoading(false);
  };

  const handleSearchClear = async () => {
    localStorage.removeItem("merchantIdToFilterTransaction");
    localStorage.removeItem("subaccountIdToFilterTransaction");
    localStorage.removeItem("receiverIdToFilterTransaction");
    localStorage.removeItem("receiverAccountIdToFilterTransaction");
    setIsLoading(true);
    setIsFilterLoading(true);
    setFilters(initialFilters);
    setSubaccountList(null);
    setMerchantIdToGetSubaccount(null);
    setPage(1);
    setSelectedDates(initialDateRangeFilter);
    await fetchTransactions({ page: 0, limit: rowPerPage, ...initialFilters }, true);
    setIsFilterLoading(false);
  };

  const triggerReloadList = async () => {
    setIsLoading(true);
    await fetchTransactions({ page: page, limit: rowPerPage, ...filters });
  }
  
  const fetchMerchants = async () => {
    try {
      let requestUrl = `/api/merchant/get-all-merchants`;

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.merchants?.payload;

      setMerchantList(data);
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const fetchSubaccounts = async () => {
    if (merchantIdToGetSubaccount !== null) {
      try {
        setIsSubaccountListLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
          `/api/subaccount/get-all-subaccounts-for-merchant?merchantId=${merchantIdToGetSubaccount}`
        );

        const data = response.data.subaccounts?.payload;

        setSubaccountList(data);
        setIsSubaccountListLoading(false);
      } catch (error) {
        console.error("Error fetching countries", error);
      }
    }
  };

  const fetchReceivers = async () => {
    console.log('fetchReceivers');
    try {
      let requestUrl = '';
      if(userRole === 'superadmin') {
        setIsReceiverListLoading(true);
        requestUrl = `/api/receiver/get-all-receivers?subaccount_id=${subaccountIdToGetReceiver}`
      } else {
        requestUrl = `/api/receiver/get-all-receivers?subaccount_id=${subaccount_id}`
      }

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.receivers?.payload;

      setReceiverList(data);
      setIsReceiverListLoading(false);
    } catch (error) {
      console.error("Error fetching receivers", error);
    }
  };

  const fetchReceiverAccounts = async () => {
    if(receiverIdToGetReceiverAccount !== null) {
      try {
        setIsReceiverAccountListLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
          `/api/receiveraccount?receiver_id=${receiverIdToGetReceiverAccount}`
        );
  
        const data = response.data.receiver_accounts?.payload;
  
        setReceiverAccountList(data);
        setIsReceiverAccountListLoading(false);
      } catch (error) {
        console.error("Error fetching receivers", error);
      }
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <TransactionSearch
        merchantList={merchantList}
        merchantIdToGetSubaccount={merchantIdToGetSubaccount}
        setMerchantIdToGetSubaccount={setMerchantIdToGetSubaccount}

        subaccountList={subaccountList}
        subaccountIdToGetReceiver={subaccountIdToGetReceiver}
        setSubaccountIdToGetReceiver={setSubaccountIdToGetReceiver}
        isSubaccountListLoading={isSubaccountListLoading}

        receiverList={receiverList}
        receiverIdToGetReceiverAccount={receiverIdToGetReceiverAccount}
        setReceiverIdToGetReceiverAccount={setReceiverIdToGetReceiverAccount}
        isReceiverListLoading={isReceiverListLoading}
        
        receiverAccountList={receiverAccountList}
        isReceiverAccountListLoading={isReceiverAccountListLoading}
        
        isFilterLoading={isFilterLoading}
        filters={filters}
        setFilters={setFilters}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClear={handleSearchClear}
      />
      <TransactionList
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};

export default Transaction;
