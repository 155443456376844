import React, { useState, useEffect } from "react";

const RoleDropdown = ({ newUser, handleInputChange, roleOptions }: any) => {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const options = roleOptions;
      setRoles(options);
    };

    fetchRoles();
  }, [roleOptions]);

  const handleOptionClick = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleInputChange({
      target: { name: "role", value: event.target.value },
    });
  };

  return (
    <div>
      <select
        id="role"
        defaultValue={newUser.role}
        onChange={handleOptionClick}
        className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md  custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm"
      >
        {roles.map((role: string) => (
          <option key={role} value={role}>
            {role}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RoleDropdown;
