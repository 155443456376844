/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState } from "react";
import { Box } from "@mui/material";
import { Button } from '@chakra-ui/react'
import { FaPlus } from "react-icons/fa";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import CreateReceiverAccountModal from "./CreateReceiverAccountModal";
import UpdateReceiverAccountModal from "./UpdateReceiverAccountModal";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineEdit } from "react-icons/ai";
import SweetAlert2 from "react-sweetalert2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type BankAccountData = {
  account_number: string;
  bank_code: string;
  bsb: string;
};

type RowData = {
  id: string;
  bank_account_data: BankAccountData;
  created_at: string;
};

const ReceiverAccountList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
  receiverId: string;
  triggerReloadReceiverAccountList(): void;
}> = ({ isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey, receiverId, triggerReloadReceiverAccountList }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const [isOpenCreateReceiverAccountModal, setIsOpenCreateReceiverAccountModal] = useState<boolean>(false);
  const [isOpenUpdateReceiverAccountModal, setIsOpenUpdateReceiverAccountModal] = useState<boolean>(false);
  const [receiverAccountDetailToUpdate, setReceiverAccountDetailToUpdate] = useState<any>(null);
  const [swalProps, setSwalProps] = useState<any>({});

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 350,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        params.row.id,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      width: 175,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "bank_code", headerName: "Bank Code", flex: 1, sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.bank_account_data.bank_code,
    },
    {
      field: "bsb", headerName: "BSB", flex: 1, sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.bank_account_data.bsb,
    },
    {
      field: "account_number", headerName: "Account No.", flex: 1, sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.bank_account_data.account_number,
    },
    {
      field: "actions", headerName: "", sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title="Update" arrow placement="right">
            <div className="mr-5">
              <IconButton
                id={"button-update-" + params?.row?.id}
                onClick={() => handleClickUpdate(params.row.id)}
              >
                <AiOutlineEdit className="h-5 w-5 text-navy-700" />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  interface CustomerStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start',
    row: ''
  };

  const filterColumns = () => {
    let rebuildColumns = [];
    if (userRole !== "superadmin") {
      const excludeFields = ["actions"];
      rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
    } else {
      rebuildColumns = columns;
    }
    return rebuildColumns;
  };

  const showAddAccountModal = () => {
    setIsOpenCreateReceiverAccountModal(true);
  }

  const closeAddAccountModal = () => {
    setIsOpenCreateReceiverAccountModal(false);
  }

  const handleClickUpdate = (id: any) => {
    const receiverAccount = tableData.filter((x: any) => x.id === id)[0];
    setReceiverAccountDetailToUpdate(receiverAccount);
    showUpdateAccountModal();
  };

  const showUpdateAccountModal = () => {
    setIsOpenUpdateReceiverAccountModal(true);
  }

  const closeUpdateAccountModal = () => {
    setReceiverAccountDetailToUpdate(null);
    setIsOpenUpdateReceiverAccountModal(false);
  }

  const triggerReloadListForUpdate = () => {
    setReceiverAccountDetailToUpdate(null);
    triggerReloadReceiverAccountList();
  }

  return (
    <div className="p-8">

      {
        receiverId !== null ?
          <>
            <CreateReceiverAccountModal setSwalProps={setSwalProps} triggerReloadReceiverAccountList={triggerReloadReceiverAccountList} receiverId={receiverId} isOpen={isOpenCreateReceiverAccountModal} onClose={() => closeAddAccountModal()} />
            { receiverAccountDetailToUpdate &&
              <UpdateReceiverAccountModal setSwalProps={setSwalProps} triggerReloadReceiverAccountList={triggerReloadListForUpdate} receiverAccountDetail={receiverAccountDetailToUpdate} isOpen={isOpenUpdateReceiverAccountModal} onClose={() => closeUpdateAccountModal()} />
            }
          </>
          : null
      }

      <div className="flex mb-6">
        {(userRole === 'admin' || userRole === 'merchant') &&
          <Button
            isDisabled={isLoading}
            onClick={() => showAddAccountModal()}
            className="btn btn-action"
          >
            <FaPlus />&nbsp;Create New Account
          </Button>
        }
      </div>
      <Box
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '8px 16px',
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px"
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none"
            },
          }}
          loading={isLoading}
          rows={tableData || []}
          columns={filterColumns()}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={40}
          columnHeaderHeight={40}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>      

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

      </SweetAlert2>
    </div>
  );
};

export default ReceiverAccountList;
