/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Dropdown from "components/fields/dropdown";
import axios from "axios";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";

import { useRouter } from "routes/hooks/useRouter";
import { Auth } from "aws-amplify";

const Navbar = (props: {
  isIdle: boolean;
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText, isIdle } = props;
  // const [darkmode, setDarkmode] = React.useState(false);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const router = useRouter();
  const navigate = useNavigate();

  const email = localStorage.getItem("userEmail");
  const currentUserRole = localStorage.getItem("userRole");
  const merchantsCount = localStorage.getItem("merchantsCount");
  const subaccountsCount = localStorage.getItem("subaccountsCount");

  const handleLogout = async () => {
    try {
      await Auth.signOut();

      localStorage.clear();

      router.push("/auth/sign-in");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleSwitchSubaccount = async () => {
    try {
      const merchantId = localStorage.getItem("merchantId");

      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const cognitoId = idToken.payload.sub;
      const reSelectSubAccount = true;

      const responseAuth = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/auth`,
        { email: email, cognitoId: cognitoId }
      );

      const userRoles = responseAuth.data.userRoles;

      navigate("/auth/subaccount-selection", {
        state: { merchantId, cognitoId, userRole: userRoles, reSelectSubAccount},
      });
    } catch (error) {
      console.error("Error switching subaccount: ", error);
    }
  };

  const handleSwitchMerchant = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const cognitoId = idToken.payload.sub;

      const responseAuth = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/auth`,
        { email: email, cognitoId: cognitoId }
      );

      const userRoles = responseAuth.data.userRoles;

      navigate("/auth/merchant-selection", {
        state: { cognitoId, userRole: userRoles },
      });
    } catch (error) {
      console.error("Error switching merchant: ", error);
    }
  };

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUserEmail(userInfo.attributes.email);
        setUserName(userInfo.username);
      } catch (error) {
        console.error("Error fetching user info: ", error);
      }
    };

    fetchUserEmail();
  }, []);

  return (
    <nav className="mt-6 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl">
      <div className="ml-[6px]">
        <div className="h-6 w-[350px] pt-1">
          <span className="text-sm font-bold text-navy-700  dark:hover:text-white">
            Payswiftly
          </span>
          <span className="mx-1 text-sm text-navy-700 hover:text-navy-700">
            &nbsp;{">"}&nbsp;
          </span>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="text-2xl capitalize text-navy-700 tracking-tighter ml-[-2px] mt-2 mb-4">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      { !isIdle ?
      <div id="navbar-profile" className="relative mt-[3px] flex flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:gap-2">
        {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div> */}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div> */}
        <Dropdown
          button={
            <AiOutlineUser className="cursor-pointer text-3xl text-green-500" />
          }
          children={
            <div
              className={`flex w-auto min-w-[230px] flex-col justify-start rounded-lg bg-white bg-cover bg-no-repeat shadow-md dark:!bg-navy-700 dark:text-white dark:shadow-none ${currentUserRole === "SuperAdmin" || (merchantsCount === '1' && subaccountsCount === '1') ? "h-[130px]" : merchantsCount === '1' ? "h-[160px]" : "h-[195px]"}`}
            >
              <div className="ml-4 mt-3 mr-4">
                <div className="flex items-center gap-2">
                  <p className="text-md font-bold text-navy-700 dark:text-white">
                    {/* 👋&nbsp;Hi, {userName} */}
                    Hi, {userName}
                  </p>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 mr-4 flex flex-col">
                <div>{userEmail}</div>
                {currentUserRole !== "SuperAdmin" && merchantsCount !== '1' && (
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSwitchMerchant();
                    }}
                    className="mt-3 text-sm font-medium text-green-500 hover:text-green-700"
                  >
                    Switch Merchants
                  </a>
                )}

                {currentUserRole !== "SuperAdmin" && subaccountsCount !== '1' && (
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSwitchSubaccount();
                    }}
                    className="mt-3 text-sm font-medium text-green-500 hover:text-green-700"
                  >
                    Switch Subaccounts
                  </a>
                )}

                <a
                  href=" "
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Sign Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-10 -right-[0.5rem] w-max"}
        />
      </div>
      : null }
    </nav>
  );
};

export default Navbar;
