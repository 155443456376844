import { useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const updateAuthStatus = async () => {
    setIsAuthenticating(true);
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error updating auth status:", error);
      setIsAuthenticated(false);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const checkAuthStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (error) {
      return false;
    }
  };

  const fetchPermissions = async (user: any, callback: any) => {
    try {
      const userRole = localStorage.getItem("userRole");
      const merchantId = localStorage.getItem("merchantId");
      const subaccountId = localStorage.getItem("subaccountId");

      if(merchantId && subaccountId && userRole) {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/api/permission/get-all-permissions`
        );
        const rolesWithPermissions = response.data.permissions;
        const userGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        const userRoleData = userGroups ? rolesWithPermissions.filter((role: any) =>
          userGroups.includes(role.role_name)
        ) : [];
        if (userRoleData.length > 0) {
          if (userRoleData[0].role_name === "SuperAdmin") {
            const superAdminPermissions = userRoleData[0].permission;
            if (typeof callback === "function") {
              callback();
              return superAdminPermissions;
            }
          } else {
            const tempUserPermissions: any = {};
            const roleData = userRoleData.find(
              (roleData: any) => roleData.role_name === userRole
            );

            if (roleData) {
              tempUserPermissions[`${merchantId}-${subaccountId}`] =
                roleData.permission;
            }

            if (typeof callback === "function") {
              callback();
              return tempUserPermissions;
            }
          }
        } else {
          // console.log("User group does not exist.");
          return false;
        }
      } else {
        Auth.signOut();
        // console.log("User does not complete sign in flow.");
        return false;
      }
    } catch (error) {
      // console.error("Failed to fetch permissions:", error);
    }
  };
  return {
    isAuthenticated,
    isAuthenticating,
    fetchPermissions,
    updateAuthStatus,
    checkAuthStatus
  };
}
