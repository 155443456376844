import { Auth } from "aws-amplify";
import { useRouter } from "routes/hooks/useRouter";

export const Logout = (props: {}): JSX.Element => {

  const router = useRouter();

  const handleLogout = async () => {
    try {
      await Auth.signOut();

      localStorage.clear();

      router.push("/auth/sign-in");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="my-[3px] flex items-center cursor-pointer hover:!text-white text-md pt-1 pb-1 px-8"
      onClick={(e) => {
        e.preventDefault();
        handleLogout();
      }}
    >
      <span className="">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
        </svg>
      </span><p className="leading-1 ml-4 font-medium text-gray-600">Sign Out</p>
    </div>
  );
};

export default Logout;