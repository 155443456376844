import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import "./main.css?v=1";
import App from "./App";
import { StrictMode } from "react";
import { SubaccountProvider } from "./store/SubaccountProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_REGION,
      },
    ],
  },
});

root.render(
  <StrictMode>
    <SubaccountProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SubaccountProvider>
  </StrictMode>
);
