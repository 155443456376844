/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import BatchFailedReportSearch from "./components/BatchFailedReportSearch";
import BatchFailedReportList from "./components/BatchFailedReportList";
import moment from "moment";

interface Filters {
  [key: string]: any;
}
interface PayloadType {
  [key: string]: any;
}

const rowPerPage = 10;

const initFrom = moment().subtract(1, 'month');
const initTo = moment();
const initialDateRangeFilter = [initFrom.toDate(), initTo.toDate()];

const initialFilters: Filters = {
  date_type: "c",
  from: initFrom,
  to: initTo,
  asset: "",
  status: "",
};

const BatchFailedReport = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);  
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDateRangeFilter);
  const subaccount_id = localStorage.getItem("subaccountId");
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const fetchBatch = async (payload: PayloadType = {}, isReset = false) => {
    try {

      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        subaccount: payload?.subaccount_id,
        from: moment(!isReset ? selectedDates[0] : initFrom, 'DD/MM/YYYY').format('YYYY-MM-DD 00:00'),
        to: moment(!isReset ? selectedDates[1] : initTo, 'DD/MM/YYYY').format('YYYY-MM-DD 23:59')
      };

      if (userRole === 'superadmin') {
        delete queryObject.subaccount;
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/batch/get-failed-report?${queryString}`
      );

      let newData = response.data.batches?.payload;

      if (newData && newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);
      // Manage Last Key and Total Record
      let newLastKey = response.data.batches?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      subaccount_id: subaccount_id,
      ...filters
    };

    // If click prev pagination
    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    // if select new row per page, reset page to 0, reset lastkey
    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        subaccount_id: subaccount_id,
        ...filters
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchBatch(newFilters);
  };

  const handleSearchSubmit = () => {
    setIsLoading(true);
    fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
  };

  const handleSearchClear = () => {
    handleResetTable();
  };

  const handleResetTable = () => {
    setIsLoading(true);
    setFilters(initialFilters);
    setPage(0);
    setCurrentLastKey("");
    setLastKeys([]);
    setSelectedDates(initialDateRangeFilter);
    fetchBatch({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...initialFilters }, true);
  };

  return (
    <>
      <BatchFailedReportSearch
        isDownloading={isDownloading}
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClear={handleSearchClear}
      />
      <BatchFailedReportList
        isDownloading={isDownloading}
        setIsDownloading={setIsDownloading}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
      />
    </>
  );
};

export default BatchFailedReport;
