import React, { ReactElement } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import { Button, Badge } from '@chakra-ui/react'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineFileSearch } from "react-icons/ai";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type Merchant = {
  id: string;
  name: string;
};

type Subaccount = {
  id: string;
  name: string;
};

type RowData = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  reference: string;
  status: string;
  kyc_status: string;
  created_at: string;
  merchant: Merchant;
  subaccount: Subaccount;
};

const ReceiverList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
}> = ({ isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey }) => {

  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 340,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
      params.row.id,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      width: 175,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "subaccount",
      headerName: "Subaccount",
      minWidth: 160,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.subaccount.name,
    },
    { field: "first_name", headerName: "First Name", flex: 1, sortable: false },
    { field: "last_name", headerName: "Last Name", flex: 1, sortable: false },
    { field: "email", headerName: "Email", flex: 1, sortable: false },
    {
      field: "status", headerName: "Status", flex: 1, sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const customerStatus = params.value as string;
        const statusItem = statusList.find(item => item.status === customerStatus);
        return statusItem ? statusItem.display : null;
      }
    },
    {
      field: "kyc_status", headerName: "KYC Status", flex: 1, sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const kycStatus = params.value as string;
        const statusItem = statusList.find(item => item.status === kycStatus);
        return statusItem ? statusItem.display : null;
      }
    },
    {
      field: "actions", headerName: "", 
      align: 'right', sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title="View More Details" arrow placement="right">
            <div className="mr-5">
              <IconButton
                id={"button-detail-" + params?.row?.id}
                onClick={() => handleClickDetail(params.row.id)}
              >
                <AiOutlineFileSearch className="h-5 w-5 text-navy-700" />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
    },
  ];
  
  interface CustomerStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: CustomerStatusItems[] = [
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "completed", value: 'completed', display: <Badge className={`${statusClasses} status-completed`}>Completed</Badge> },
    { status: "active", value: 'active', display: <Badge className={`${statusClasses} status-active`}>Active</Badge> },
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
  };

  const ellipsisUUID = (uuid: string): string => {
    const sections = uuid.split('-');

    if (sections.length < 4) {
      return uuid;
    }

    return `${sections[0]}-${sections[1]}...`;
  };

  const handleClickDetail = (id: any) => {
    navigate(`/${userRole}/receiver/${id}`);
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start',
    row: ''
  };

  const filterColumns = () => {
    let rebuildColumns = [];
    if (userRole !== "superadmin") {
      const excludeFields = ["subaccount"];
      rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
    } else {
      rebuildColumns = columns;
    }
    return rebuildColumns;
  };

  return (
    <div>
      
      <div className="flex flex-wrap mt-6 pb-4 pl-3">
          <h3 className="text-lg text-navy-700 font-bold dark:text-white">
            Receivers 
          </h3>
        </div>
      <Box
        className="w-full card"
      >

          <DataGrid
              sx={{ 
                '& .MuiDataGrid-cell': {
                  padding: '8px 16px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '8px 16px',
                },
                "& .MuiTablePagination-input": {
                  marginRight: "-20px"
                },
                "& .MuiTablePagination-displayedRows": {
                  display: "none"
                },
              }} 
            loading={isLoading}
            rows={tableData || []}
            columns={filterColumns()}
            getRowId={(row) => row.id}
            rowCount={rowCount}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[10, 25, 50, 100]}
            rowHeight={48}
            columnHeaderHeight={48}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            classes={customDataGridStyles}
            hideFooter={currentLastKey === "" && page === 0}
            autoHeight
          />
      </Box>
    </div>
  );
};

export default ReceiverList;
