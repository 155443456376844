import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import useRoutes from "routes";

export default function Public() {
  const { routes } = useRoutes(null);
  const publicRoutes = routes.public;

  useEffect(() => {
    const updateFavicon = (newFavicon32: string, newFavicon16: string) => {
      const icon32 = document.querySelector('link[rel="icon"][sizes="32x32"]');
      const icon16 = document.querySelector('link[rel="icon"][sizes="16x16"]');

      if (icon32) {
        icon32.remove();
      }
      if (icon16) {
        icon16.remove();
      }

      const newIcon32 = document.createElement('link');
      newIcon32.rel = 'icon';
      newIcon32.type = 'image/png';
      newIcon32.setAttribute('sizes', '32x32');
      newIcon32.href = `${newFavicon32}?t=${new Date().getTime()}`;

      const newIcon16 = document.createElement('link');
      newIcon16.rel = 'icon';
      newIcon16.type = 'image/png';
      newIcon16.setAttribute('sizes', '16x16');
      newIcon16.href = `${newFavicon16}?t=${new Date().getTime()}`;

      document.head.appendChild(newIcon32);
      document.head.appendChild(newIcon16);
    };

    const domain = window.location.hostname;
    const pathname = window.location.pathname;
    if (pathname.includes("confirm")) {
      if (domain.includes("localhost") || domain.includes(process.env.REACT_APP_HOSTEDZONE_DOMAIN)) {
        updateFavicon('/favicon-32x32.png', '/favicon-16x16.png');
      } else {
        updateFavicon('/blank-favicon.ico', '/blank-favicon.ico');
      }
    } else {
      updateFavicon('/favicon-32x32.png', '/favicon-16x16.png');
    }
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full bg-darkPrimary">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex justify-center h-full min-h-screen w-full">
            <div className="mx-auto flex flex-col pt-12 lg:px-8 lg:pt-0 xl:px-0">
              <Routes>
                {getRoutes(publicRoutes)}
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}