import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import SweetAlert2 from "react-sweetalert2";
import { Button } from '@chakra-ui/react';
import { IoCheckmark } from "react-icons/io5";

const PayoutCancelConfirmModal: React.FC<{
    payoutToCancel: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
}> = ({ isOpen, onClose, triggerReload, payoutToCancel }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [swalProps, setSwalProps] = useState<any>({});

    const closeModal = async () => {
        onClose();
    };

    const displayAmount = (amount: any) => {
        if (amount === 0) {
            return "$0.00";
        }
        return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            await axios.put(process.env.REACT_APP_API_URL + `/api/payout/cancel/${payoutToCancel.id}`, {})
                .then(function (response) {
                    triggerReload();
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Payout has been cancel",
                            showConfirmButton: true,
                            didClose: () => {                                
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    return (
        <>
            { payoutToCancel &&
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to cancel the payout?</h1>
                            <div className="mb-[20px]">
                                <div className="mb-8">
                                    Please confirm if you wish to cancel the current payout. Cancelling this payout will discard all the details, and the transaction will not be processed.
                                </div>                                
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">Email:</span> {payoutToCancel.receiver.email}
                                </div>
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">BSB:</span> {payoutToCancel.receiver_account.bsb}
                                </div>
                                <div className="mb-4">
                                    <span className="mr-1 font-bold">Account No.:</span> {payoutToCancel.receiver_account.account_number}
                                </div>
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">Amount:</span> {displayAmount(payoutToCancel.amount)} {payoutToCancel.asset}
                                </div>
                                <div className="mb-1">
                                    <span className="mr-1 font-bold">Note:</span> {payoutToCancel.note}
                                </div>
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    isDisabled={isProcessing}
                                    className="btn btn-default"
                                >
                                    Close
                                </Button>
                                <Button
                                    onClick={() => handleSubmit()}
                                    isLoading={isProcessing}
                                    loadingText='Processing...'
                                    className="btn btn-primary">
                                    <IoCheckmark className="h-4 w-4" />&nbsp;Confirm
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal> }

            <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

            </SweetAlert2>
        </>
    );
};

export default PayoutCancelConfirmModal;
