/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import AutoComplete from 'react-select';

const InputStyle =
  "daterange-picker font-bold text-navy-700 h-10 mt-2 w-full items-center shadow-sm rounded-md border bg-white/0 p-3 text-sm outline-none shadow-sm border-gray-300 dark:!border-white/10 dark:text-white";

const dateRangePropsConfigs = {
  dateNavBtnProps: {
    variant: "outline",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "rgb(233 236 239 / 1)", // Default border color
      borderRadius: "2px",
      _hover: {
        background: "#4fb06d", // Background color on hover
        color: "white",
      },
    },
    isInRangeBtnProps: {
      background: "#65e68d", // Background color for dates in the selected range
      color: "white", // Text color for dates in the selected range
    },
    selectedBtnProps: {
      background: "#4fb06d", // Background color for selected date
      color: "white", // Text color for selected date
    },
    todayBtnProps: {
      // color: "#422afb", // Background color for today's date
      // fontWeight: "bold",
    },
  },
  inputProps: {
    minWidth: "230px",
    className: InputStyle,
  },
  popoverCompProps: {
    popoverContentProps: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Apply custom box shadow
    },
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: "#81E6D9", // Border color for the calendar wrapper
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: "5px",
    },
    dividerProps: {
      display: "none",
    },
  },
  weekdayLabelProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
  dateHeadingProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
};

const BatchSearch: React.FC<{
  isLoading: boolean;
  isFilterLoading: boolean;
  filters: any;
  setFilters: any;
  setSelectedDates: any;
  selectedDates: any;
  subaccountList: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
}> = ({ isLoading, isFilterLoading, filters, setFilters, setSelectedDates, selectedDates, subaccountList, handleSearchSubmit, handleSearchClear }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const [maxDate, setMaxDate] = useState<any>(new Date());

  const [subaccountAutoCompleteOptions, setSubaccountAutoCompleteOptions] = useState<any>(null);
  const subaccountAutoCompleteRef = useRef(null);
  useEffect(() => {
    if (subaccountList !== null) {
      let rebuildListArray: any = [];
      if (subaccountList.length > 0) {
        subaccountList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.suba_name
          }
          rebuildListArray.push(newObj);
        });
      }

      setSubaccountAutoCompleteOptions(rebuildListArray);
    }
  }, [subaccountList]);

  const handleSubaccountAutoCompleteChange = (item: any) => {
    localStorage.setItem("subaccountIdToFilterBatch", "");
    if (item !== null) {
      localStorage.setItem("subaccountIdToFilterBatch", item.value);
      setFilters({ ...filters, subaccount_id: "" });
    } else {
      setFilters({ ...filters, subaccount_id: "" });
    }
  };

  const noSubaccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Subaccount not found for "{inputValue}"</div>;
  };

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });

    if (id === 'date_type') {
      if (value === 'p') {
        setMaxDate(false);
      } else {
        setMaxDate(new Date());
      }
    }
  };

  const handleClear = () => {
    subaccountAutoCompleteRef.current?.clearValue();
    handleSearchClear();
  };

  return (
    <Box
      className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Date Type
          </label>
          <Select isDisabled={isLoading} id="date_type" value={filters.date_type} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="c">Created Date</option>
            <option value="p">Process Date</option>
          </Select>
        </div>
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Date
          </label>
          <RangeDatepicker
            disabled={isLoading}
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            propsConfigs={dateRangePropsConfigs}
            configs={{
              dateFormat: 'MMM d, yyyy'
            }}
            maxDate={maxDate}
          />
        </div>
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-4 text-sm text-navy-700 dark:text-white font-bold">
            Asset
          </label>
          <Select isDisabled={isLoading} id="asset" value={filters.asset} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="AUD">AUD</option>
          </Select>
        </div>
        <div className="flex-1 w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Status
          </label>
          <Select isDisabled={isLoading} id="status" value={filters.status} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="0">Queued</option>
            <option value="1">In Progress</option>
            <option value="2">Settled</option>
            <option value="3">Settled with Error</option>
            <option value="4">Cancelled</option>
            <option value="5">Failed</option>
          </Select>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Type
          </label>
          <Select isDisabled={isLoading} id="batch_type" value={filters.batch_type} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="PAYOUTS">Payouts</option>
            <option value="API">API</option>
          </Select>
        </div>
        {userRole === 'superadmin' &&
          <div className="w-1/4 p-2 pt-0">
            <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
              Subaccount
            </label>
            <AutoComplete
              ref={subaccountAutoCompleteRef}
              className="autocomplete-override mt-2"
              classNamePrefix="search-payout-receiver"
              isDisabled={isLoading || isFilterLoading || subaccountAutoCompleteOptions === null}
              placeholder={subaccountAutoCompleteOptions === null ? 'Retrieving...' : 'All'}
              options={subaccountAutoCompleteOptions}
              onChange={(item: any) => handleSubaccountAutoCompleteChange(item)}
              noOptionsMessage={noSubaccountOptionsMessageRenderer}
            />
          </div>
        }
        <div className="flex-1 p-2 pt-0 flex justify-end items-end">
          <Button
            isDisabled={isLoading || isFilterLoading}
            onClick={() => handleSearchClear()}
            className="mr-3 btn btn-default"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading || isFilterLoading}
            onClick={() => handleSearchSubmit()}
            className="btn btn-primary"
          >
            Search Batches
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default BatchSearch;
