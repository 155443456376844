/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { PiCaretDownBold, PiCaretRightBold } from "react-icons/pi";

interface SubMenuItem {
  layout: string;
  path: string;
  name: string;
}

export const SidebarLinks = (props: {
  routes: RoutesType[];
  userRole: string;
  onClose: () => void;
}): JSX.Element => {
  let location = useLocation();

  const { routes, userRole, onClose } = props;

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(
    new Array(routes.length).fill(false)
  );

  const submenuRefs = useRef(
    routes.map(() => React.createRef<HTMLUListElement>())
  );

  useEffect(() => {
    // Determine which submenu should be open based on the active route
    routes.forEach((route, index) => {
      if (route.layout === "/" + userRole.toLowerCase() && route?.hasSubMenu) {
        const hasActiveChild = route.subMenuItems.some((subMenuItem: any) =>
          location.pathname.includes(route.layout + "/" + subMenuItem.path)
        );
        if (hasActiveChild) {
          const updatedSubMenuState = [...isSubMenuOpen];
          updatedSubMenuState[index] = true;
          setIsSubMenuOpen(updatedSubMenuState);
        } else {
          const updatedSubMenuState = [...isSubMenuOpen];
          updatedSubMenuState[index] = false;
          setIsSubMenuOpen(updatedSubMenuState);
        }
      }
    });

    handleRouteChangeForMenu();
  }, [location.pathname]); // Update when location.pathname changes

  const handleSubMenuToggle = (index: number) => {
    const updatedSubMenuState = [...isSubMenuOpen];
    updatedSubMenuState[index] = !updatedSubMenuState[index];
    setIsSubMenuOpen(updatedSubMenuState);
  };

  const activeRoute = (routeName: string) => {
    const expectedPath = `/${userRole.toLowerCase()}/${routeName}`;
    return location.pathname.includes(expectedPath);
  };

  const handleRouteChangeForMenu = () => {
    if(window.innerWidth < 1200) {
      onClose();
    }

    return true;
  };

  const renderSubMenu = (
    subMenuItems: SubMenuItem[],
    parentIndex: number,
    userRole: string
  ) => {
    return (
      <ul
      className={`overflow-hidden transition-all duration-500 ease-in-out ${
        isSubMenuOpen[parentIndex] ? "max-h-[500px] opacity-100  mb-2" : "max-h-0 opacity-0"
      } pb-2 pl-11`}
      >
        {subMenuItems.map((route: SubMenuItem, subIndex: number) => (
          <li
            key={subIndex}
            className={`relative py-2 pl-10 text-md border-l border-gray-600 hover:cursor-pointer ${
              activeRoute(route.path)
              ? "font-bold text-white"
              : "font-medium text-gray-600"
            }`}
          >
            <Link
              to={route.layout + "/" + route.path}
              className="flex items-center"
            >
              <span>{route.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route, index) => {
      if (route.layout === "/" + userRole.toLowerCase() && !route?.hasSubMenu) {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div className="relative mb-5 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer text-md items-center px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-green-400"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex ${
                    activeRoute(route.path) === true
                      ? "font-bold text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>

                {activeRoute(route.path) === true ? (
                  <div className="absolute -top-1 right-0 h-9 w-1 bg-green-500 dark:bg-green-400" />
                ) : null}
              </li>
            </div>
          </Link>
        );
      } else if (route?.hasSubMenu) {
        const isActiveParentMenu = location.pathname.includes(
          route.layout + "/" + route.path
        );

        return (
          <div key={index}>
            <div className="relative mb-3 hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center text-md pt-1 pb-1 px-8"
                key={index}
                onClick={() => handleSubMenuToggle(index)}
              >
                <span
                  className={`${
                    isActiveParentMenu
                    ? "font-bold text-green-400"
                    : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 ${
                    isActiveParentMenu
                    ? "font-bold text-white"
                    : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </p>
                <div className="ml-auto mr-4">
                  {isSubMenuOpen[index] ? (
                    <PiCaretDownBold
                      className={`leading-1 ml-4 ${
                        isActiveParentMenu
                        ? "font-bold text-white"
                        : "font-medium text-gray-600"
                      }`}
                    />
                  ) : (
                    <PiCaretRightBold
                      className={`leading-1 ml-4 ${
                        isActiveParentMenu
                        ? "font-bold text-white"
                        : "font-medium text-gray-600"
                      }`}
                    />
                  )}
                </div>
              </li>
              {isActiveParentMenu ? (
                <div className="absolute -top-1 right-0 h-9 w-1 rounded-md bg-green-500 dark:bg-green-400" />
              ) : null}
            </div>
            {/* Render submenu */}
            {renderSubMenu(route.subMenuItems, index, userRole)}
          </div>
        );
      }
    });
  };

  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
