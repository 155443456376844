/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import InputField from "components/fields/InputField";

const AccountSearch: React.FC<{
  isLoading: boolean;
  filters: any;
  setFilters: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
}> = ({ isLoading, filters, setFilters, handleSearchSubmit, handleSearchClear }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });
  };

  return (
    <Box
      className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="flex-1 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Subaccount Name
          </label>
          <InputField
            disabled={isLoading}
            variant="auth"
            extra="shadow-sm"
            label=""
            placeholder=""
            id="suba_name"
            type="text"
            autoComplete="off"
            value={filters.suba_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex-1 p-2 pt-0 flex justify-end items-end">
          <Button
            isDisabled={isLoading}
            onClick={() => handleSearchClear()}
            className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading}
            onClick={() => handleSearchSubmit()}
            className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
          >
            Search Accounts
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default AccountSearch;
