import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import AutoComplete from 'react-select';

const ReceiverSearch: React.FC<{
  isLoading: boolean;
  filters: any;
  setFilters: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
  isFilterLoading: boolean;
  merchantList: any;
  subaccountList: any;
  isSubaccountListLoading: boolean;
  merchantIdToGetSubaccount: any;
  setMerchantIdToGetSubaccount: any;
}> = ({ isLoading, filters, setFilters, handleSearchSubmit, handleSearchClear, isFilterLoading, merchantList, subaccountList, isSubaccountListLoading, merchantIdToGetSubaccount, setMerchantIdToGetSubaccount }) => {
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const [merchantAutoCompleteOptions, setMerchantAutoCompleteOptions] = useState<any>(null);
  const merchantAutoCompleteRef = useRef(null);

  const [subaccountAutoCompleteOptions, setSubaccountAutoCompleteOptions] = useState<any>(null);
  const subaccountAutoCompleteRef = useRef(null);

  useEffect(() => {
    if (merchantList !== null) {
      let rebuildListArray: any = [];
      if (merchantList.length > 0) {
        merchantList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.name
          }
          rebuildListArray.push(newObj);
        });
      }

      setMerchantAutoCompleteOptions(rebuildListArray);
    }
  }, [merchantList]);

  useEffect(() => {
    if (subaccountList !== null) {
      let rebuildListArray: any = [];
      if (subaccountList.length > 0) {
        subaccountList.forEach((i: any) => {
          let newObj = {
            value: i.id,
            label: i.suba_name
          }
          rebuildListArray.push(newObj);
        });
      }

      setSubaccountAutoCompleteOptions(rebuildListArray);
    }
  }, [subaccountList]);

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });
  };

  const handleMerchantAutoCompleteChange = (item: any) => {
    localStorage.setItem("merchantIdToFilterReceiver", "");
    if (item !== null) {
      localStorage.setItem("merchantIdToFilterReceiver", item.value);
      setFilters({ ...filters, subaccount_id: "" });
      setMerchantIdToGetSubaccount(item.value);
    } else {
      setFilters({ ...filters, subaccount_id: "" });
      setMerchantIdToGetSubaccount(null);
    }
  };

  const noMerchantOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Merchant not found for "{inputValue}"</div>;
  };

  const handleSubaccountAutoCompleteChange = (item: any) => {
    localStorage.setItem("subaccountIdToFilterReceiver", "");
    if (item !== null) {
      localStorage.setItem("subaccountIdToFilterReceiver", item.value);
    }
  };

  const noSubaccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
    return <div className="text-sm">Subaccount not found for "{inputValue}"</div>;
  };

  const handleClear = () => {
    if (userRole === 'superadmin') {
      merchantAutoCompleteRef.current.clearValue();
      subaccountAutoCompleteRef.current.clearValue();
      setSubaccountAutoCompleteOptions(null);
    }
    handleSearchClear();
  };

  return (
    <Box
      className="mt-6 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Field
          </label>
          <Select isDisabled={isLoading} id="field" value={filters.field} onChange={handleInputChange} className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm">
            <option value="">Select Field</option>
            <option value="first_name">First Name</option>
            <option value="last_name">Last Name</option>
            <option value="email">Email</option>
            <option value="receiver_id">Receiver ID</option>
            <option value="receiver_account_id">Receiver Account ID</option>
          </Select>
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Keyword
          </label>
          <InputField
            disabled={isLoading}
            variant="auth"
            extra="shadow-sm rounded-md"
            label=""
            placeholder=""
            id="keyword"
            type="text"
            autoComplete="off"
            value={filters.keyword}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Status
          </label>
          <Select isDisabled={isLoading} id="status" value={filters.status} onChange={handleInputChange} className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm">
            <option value="">All</option>
            <option value="0">Creating</option>
            <option value="1">Pending</option>
            <option value="2">Active</option>
            <option value="3">Inactive</option>
            <option value="4">Failed</option>
          </Select>
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            KYC
          </label>
          <Select isDisabled={isLoading} id="kyc_status" value={filters.kyc_status} onChange={handleInputChange} className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md  custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm">
            <option value="">All</option>
            <option value="0">Pending</option>
            <option value="1">Complete</option>
            <option value="2">Failed</option>
          </Select>
        </div>
      </div>
      <div className="flex w-full">
        {userRole === 'superadmin' &&
          <>
            <div className="w-1/4 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Merchant
              </label>
              <AutoComplete
                ref={merchantAutoCompleteRef}
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || merchantAutoCompleteOptions === null}
                placeholder={merchantAutoCompleteOptions === null ? 'Retrieving...' : 'All'}
                options={merchantAutoCompleteOptions}
                classNamePrefix="search-payout-receiver"
                onChange={(item: any) => handleMerchantAutoCompleteChange(item)}
                noOptionsMessage={noMerchantOptionsMessageRenderer}
              />
            </div>
            <div className="w-1/4 p-2 pt-0">
              <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                Subaccount
              </label>
              <AutoComplete
                ref={subaccountAutoCompleteRef}
                className="autocomplete-override mt-2"
                isDisabled={isFilterLoading || subaccountAutoCompleteOptions === null}
                placeholder={isSubaccountListLoading ? 'Retrieving...' : 'All'}
                options={subaccountAutoCompleteOptions}
                classNamePrefix="search-payout-receiver"
                onChange={(item: any) => handleSubaccountAutoCompleteChange(item)}
                noOptionsMessage={noSubaccountOptionsMessageRenderer}
              />
            </div>
          </>
        }
        <div className="flex-1 p-2 pt-0 flex justify-end items-end mt-4">
          <Button
            isDisabled={isLoading || isSubaccountListLoading}
            onClick={() => handleClear()}
            className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading || isSubaccountListLoading}
            onClick={() => handleSearchSubmit()}
            className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
          >
            Search Receivers
          </Button>
        </div>
      </div>
    </Box>

  );
};

export default ReceiverSearch;
