import React from "react";

function useOutsideAlerter(ref: any, setX: any): void {
  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

const Dropdown = (props: {
  button: JSX.Element;
  children: JSX.Element;
  classNames: string;
  animation?: string;
  maxHeight?: number;
}) => {
  const { button, children, classNames, animation, maxHeight = 500 } = props;
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);
  useOutsideAlerter(wrapperRef, setOpenWrapper);

  return (
    <div ref={wrapperRef} className="relative w-full">
      <div className="w-full" onMouseDown={() => setOpenWrapper(!openWrapper)}>
        {button}
      </div>
      {openWrapper && (
        <div
          className={`${classNames} absolute z-10 ${
            animation
              ? animation
              : "origin-top-right text-primary transition-all  duration-300 ease-in-out"
          } ${openWrapper ? "scale-100" : "scale-0"}`}
          style={{
            maxHeight: `${maxHeight}px`,
            overflowY: "auto",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
