/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";

const InputStyle =
  "daterange-picker font-bold text-navy-700  mt-2 h-10 w-full items-center shadow-sm rounded-md border bg-white/0 p-3 text-sm outline-none rounded-md shadow-sm border-gray-300 dark:!border-white/10 dark:text-white";

const dateRangePropsConfigs = {
  dateNavBtnProps: {
    variant: "outline",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "rgb(233 236 239 / 1)", // Default border color
      borderRadius: "2px",
      _hover: {
        background: "#543ffb", // Background color on hover
        color: "white",
      },
    },
    isInRangeBtnProps: {
      background: "#8d7ffc", // Background color for dates in the selected range
      color: "white", // Text color for dates in the selected range
    },
    selectedBtnProps: {
      background: "#543ffb", // Background color for selected date
      color: "white", // Text color for selected date
    },
    todayBtnProps: {
      // color: "#422afb", // Background color for today's date
      // fontWeight: "bold",
    },
  },
  inputProps: {
    minWidth: "230px",
    className: InputStyle,
  },
  popoverCompProps: {
    popoverContentProps: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Apply custom box shadow
    },
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: "#81E6D9", // Border color for the calendar wrapper
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: "5px",
    },
    dividerProps: {
      display: "none",
    },
  },
  weekdayLabelProps: {
    fontWeight: "normal",
  },
  dateHeadingProps: {
    fontWeight: "semibold",
  },
};

const BatchFailedReportSearch: React.FC<{
  isDownloading: boolean;
  isLoading: boolean;
  filters: any;
  setFilters: any;
  setSelectedDates: any;
  selectedDates: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
}> = ({ isDownloading, isLoading, filters, setFilters, setSelectedDates, selectedDates, handleSearchSubmit, handleSearchClear }) => {

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setFilters({ ...filters, [id]: value });
  };

  return (
    <Box
      className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Process Date
          </label>
          <RangeDatepicker
            disabled={isLoading || isDownloading}
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            propsConfigs={dateRangePropsConfigs}
            configs={{
              dateFormat: 'MMM d, yyyy'
            }}
            maxDate={new Date()}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Asset
          </label>
          <Select isDisabled={isLoading || isDownloading} id="asset" value={filters.asset} onChange={handleInputChange} className="h-10 mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
            <option value="">All</option>
            <option value="AUD">AUD</option>
          </Select>
        </div>
        <div className="flex-1 p-2 pt-0 flex justify-end items-end">
          <Button
            isDisabled={isLoading || isDownloading}
            onClick={() => handleSearchClear()}
            className="mr-3 btn btn-default"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading || isDownloading}
            onClick={() => handleSearchSubmit()}
            className=" btn btn-primary"
          >
            Search Failed Reports
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default BatchFailedReportSearch;
