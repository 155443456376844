import React, { createContext, useReducer, ReactNode } from "react";

// Define types for context values
interface SubaccountContextType {
  subaccountBalance: any;
  setSubaccountBalance: (payload: any) => void;
  isGettingBalance: any;
  setIsGettingBalance: (payload: any) => void;
  editBatchId: any;
  setEditBatchId: (payload: any) => void;
  isEditBatchCalled: any;
  setIsEditBatchCalled: (payload: any) => void;
}

// Define initial state type
interface InitialStateType {
  subaccountBalance: any;
  isGettingBalance: any;
  editBatchId: any;
  isEditBatchCalled: any;
}

// Define action type
type ActionType = {
  type: any;
  payload: any;
};

// Create context with initial empty object
export const SubaccountContext = createContext<SubaccountContextType | null>(null);

const initialState: InitialStateType = {
  subaccountBalance: '0.00',
  isGettingBalance: false,
  editBatchId: null,
  isEditBatchCalled: false
};

const subaccountReducer = (state: InitialStateType, action: ActionType) => {
  switch (action.type) {
    case "SET_BALANCE":
      return {
        ...state,
        subaccountBalance: action.payload,
      };
    case "SET_IS_GETTING_BALANCE":
      return {
        ...state,
        isGettingBalance: action.payload,
      };
    case "SET_EDIT_BATCH_ID":
      return {
        ...state,
        editBatchId: action.payload,
      };
    case "SET_EDIT_BATCH_CALLED":
      return {
        ...state,
        isEditBatchCalled: action.payload,
      };
    default:
      return state;
  }
};

export const SubaccountProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [subaccountState, subaccountDispatch] = useReducer(
    subaccountReducer,
    initialState
  );

  const { subaccountBalance, isGettingBalance, editBatchId, isEditBatchCalled } = subaccountState;

  const setSubaccountBalance = (payload: string) =>
    subaccountDispatch({ type: "SET_BALANCE", payload });

  const setIsGettingBalance = (payload: string) =>
    subaccountDispatch({ type: "SET_IS_GETTING_BALANCE", payload });

  const setEditBatchId = (payload: string) =>
    subaccountDispatch({ type: "SET_EDIT_BATCH_ID", payload });

  const setIsEditBatchCalled = (payload: string) =>
    subaccountDispatch({ type: "SET_EDIT_BATCH_CALLED", payload });

  return (
    <SubaccountContext.Provider value={{ subaccountBalance, setSubaccountBalance, isGettingBalance, setIsGettingBalance, editBatchId, setEditBatchId, isEditBatchCalled, setIsEditBatchCalled }}>
      {children}
    </SubaccountContext.Provider>
  );
};
