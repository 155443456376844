import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import AutoComplete from 'react-select';
import InputField from "components/fields/InputField";

const InputStyle =
  "daterange-picker font-bold text-navy-700 h-10 mt-2 w-full items-center shadow-sm rounded-md border bg-white/0 p-3 text-sm outline-none shadow-sm border-gray-300 dark:!border-white/10 dark:text-white";

const dateRangePropsConfigs = {
  dateNavBtnProps: {
    variant: "outline",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      borderColor: "rgb(233 236 239 / 1)", // Default border color
      borderRadius: "2px",
      _hover: {
        background: "#4fb06d", // Background color on hover
        color: "white",
      },
    },
    isInRangeBtnProps: {
      background: "#65e68d", // Background color for dates in the selected range
      color: "white", // Text color for dates in the selected range
    },
    selectedBtnProps: {
      background: "#4fb06d", // Background color for selected date
      color: "white", // Text color for selected date
    },
    todayBtnProps: {
      // color: "#422afb", // Background color for today's date
      // fontWeight: "bold",
    },
  },
  inputProps: {
    minWidth: "230px",
    className: InputStyle,
  },
  popoverCompProps: {
    popoverContentProps: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)", // Apply custom box shadow
    },
  },
  calendarPanelProps: {
    wrapperProps: {
      borderColor: "#81E6D9", // Border color for the calendar wrapper
    },
    contentProps: {
      borderWidth: 0,
    },
    headerProps: {
      padding: "5px",
    },
    dividerProps: {
      display: "none",
    },
  },
  weekdayLabelProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
  dateHeadingProps: {
    fontWeight: "bold",
    fontSize: 16,
    color: "rgb(26 36 74)"
  },
};

const PayoutSearch: React.FC<{
  merchantList: any;
  merchantIdToGetSubaccount: any;
  setMerchantIdToGetSubaccount: any;

  subaccountList: any;
  subaccountIdToGetReceiver: any;
  setSubaccountIdToGetReceiver: any;
  isSubaccountListLoading: boolean;

  receiverList: any;
  receiverIdToGetReceiverAccount: any;
  setReceiverIdToGetReceiverAccount: any;
  isReceiverListLoading: boolean;

  receiverAccountList: any;
  isReceiverAccountListLoading: boolean;

  isFilterLoading: boolean;
  filters: any;
  setFilters: any;
  selectedDates: any;
  setSelectedDates: any;
  handleSearchSubmit(): void;
  handleSearchClear(): void;
}> = ({
  merchantList,
  merchantIdToGetSubaccount,
  setMerchantIdToGetSubaccount,

  subaccountList,
  subaccountIdToGetReceiver,
  setSubaccountIdToGetReceiver,
  isSubaccountListLoading,

  receiverList,
  receiverIdToGetReceiverAccount,
  setReceiverIdToGetReceiverAccount,
  isReceiverListLoading,

  receiverAccountList,
  isReceiverAccountListLoading,

  isFilterLoading,
  filters,
  setFilters,
  selectedDates,
  setSelectedDates,
  handleSearchSubmit,
  handleSearchClear
}) => {

    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const [merchantAutoCompleteOptions, setMerchantAutoCompleteOptions] = useState<any>(null);
    const [subaccountAutoCompleteOptions, setSubaccountAutoCompleteOptions] = useState<any>(null);

    const merchantAutoCompleteRef = useRef(null);
    const subaccountAutoCompleteRef = useRef(null);

    useEffect(() => {
      if (merchantList !== null) {
        let rebuildListArray: any = [];
        if (merchantList.length > 0) {
          merchantList.forEach((i: any) => {
            let newObj = {
              value: i.id,
              label: i.name
            }
            rebuildListArray.push(newObj);
          });
        }

        setMerchantAutoCompleteOptions(rebuildListArray);
      }
    }, [merchantList]);

    useEffect(() => {
      if (subaccountList !== null) {
        let rebuildListArray: any = [];
        if (subaccountList.length > 0) {
          subaccountList.forEach((i: any) => {
            let newObj = {
              value: i.id,
              label: i.suba_name
            }
            rebuildListArray.push(newObj);
          });
        }

        setSubaccountAutoCompleteOptions(rebuildListArray);
      }
    }, [subaccountList]);

    const handleInputChange = async (event: any) => {
      const { id, value } = event.target;
      setFilters({ ...filters, [id]: value });
    };

    const handleMerchantAutoCompleteChange = (item: any) => {
      localStorage.setItem("merchantIdToFilterTransaction", "");
      if (item !== null) {
        localStorage.setItem("merchantIdToFilterTransaction", item.value);
        setFilters({ ...filters, subaccount_id: "" });
        setMerchantIdToGetSubaccount(item.value);
      } else {
        setFilters({ ...filters, subaccount_id: "" });
        setMerchantIdToGetSubaccount(null);
      }
    };

    const noMerchantOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
      return <div className="text-sm">Merchant not found for "{inputValue}"</div>;
    };

    const handleSubaccountAutoCompleteChange = (item: any) => {
      localStorage.setItem("subaccountIdToFilterTransaction", "");
      if (item !== null) {
        localStorage.setItem("subaccountIdToFilterTransaction", item.value);
        setFilters({ ...filters, subaccount_id: "" });
        setSubaccountIdToGetReceiver(item.value);
      } else {
        setFilters({ ...filters, subaccount_id: "" });
        setSubaccountIdToGetReceiver(null);
      }
    };

    const noSubaccountOptionsMessageRenderer = ({ inputValue }: { inputValue: string }) => {
      return <div className="text-sm">Subaccount not found for "{inputValue}"</div>;
    };

    const handleClear = () => {
      if (userRole === 'superadmin') {
        merchantAutoCompleteRef.current.clearValue();

        subaccountAutoCompleteRef.current.clearValue();
        setSubaccountAutoCompleteOptions(null);
      }
      handleSearchClear();
    };

    return (
      <Box
        className="mt-5 mb-3 flex flex-wrap items-center card card-pad"
      >
        <div className="flex w-full">
          <div className="w-1/4 p-2 pt-0">
            <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
              Date
            </label>
            <RangeDatepicker
              disabled={isFilterLoading}
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
              propsConfigs={dateRangePropsConfigs}
              configs={{
                dateFormat: 'MMM d, yyyy'
              }}
              maxDate={new Date()}
            />
          </div>
          {userRole === 'superadmin' &&
            <>
              <div className="w-1/4 p-2 pt-0">
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Merchant
                </label>
                <AutoComplete
                  ref={merchantAutoCompleteRef}
                  className="autocomplete-override mt-2"
                  isDisabled={isFilterLoading || merchantAutoCompleteOptions === null}
                  placeholder={merchantAutoCompleteOptions === null ? 'Retrieving...' : 'All'}
                  options={merchantAutoCompleteOptions}
                  classNamePrefix="search-payout-receiver"
                  onChange={(item: any) => handleMerchantAutoCompleteChange(item)}
                  noOptionsMessage={noMerchantOptionsMessageRenderer}
                />
              </div>
              <div className="w-1/4 p-2 pt-0">
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Subaccount
                </label>
                <AutoComplete
                  ref={subaccountAutoCompleteRef}
                  className="autocomplete-override mt-2"
                  isDisabled={isFilterLoading || subaccountAutoCompleteOptions === null}
                  placeholder={isSubaccountListLoading ? 'Retrieving...' : 'All'}
                  options={subaccountAutoCompleteOptions}
                  classNamePrefix="search-payout-receiver"
                  onChange={(item: any) => handleSubaccountAutoCompleteChange(item)}
                  noOptionsMessage={noSubaccountOptionsMessageRenderer}
                />
              </div>
            </>
          }
          <div className="w-1/4 p-2 pt-0">
            <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
              Payout ID
            </label>
            <InputField
              disabled={isFilterLoading}
              variant="auth"
              extra="shadow-sm"
              label=""
              placeholder=""
              id="payout_id"
              type="text"
              autoComplete="off"
              value={filters.payout_id}
              onChange={handleInputChange}
            />
          </div>
          {userRole !== 'superadmin' &&
            <>
              <div className="w-1/4 p-2 pt-0">
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Status
                </label>
                <Select isDisabled={isFilterLoading} id="status" value={filters.status} onChange={handleInputChange} className="h-10 rounded-md mt-2  custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                  <option value="">All</option>
                  <option value="pending">PENDING</option>
                  <option value="complete">COMPLETE</option>
                  <option value="cancelled">CANCELLED</option>
                  <option value="failed">FAILED</option>
                </Select>
              </div>
              <div className="flex-1 p-2 pt-0 flex justify-end items-end">
                <Button
                  isDisabled={isFilterLoading}
                  onClick={() => handleClear()}
                  className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Clear Search
                </Button>
                <Button
                  isDisabled={isFilterLoading}
                  onClick={() => handleSearchSubmit()}
                  className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
                >
                  Seach Payouts
                </Button>
              </div>
            </>
          }
        </div>
        <div className="flex w-full">
          {userRole === 'superadmin' &&
            <>
              <div className="w-1/4 p-2 pt-0">
                <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
                  Status
                </label>
                <Select isDisabled={isFilterLoading} id="status" value={filters.status} onChange={handleInputChange} className="h-10 rounded-md mt-2  custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                  <option value="">All</option>
                  <option value="pending">PENDING</option>
                  <option value="complete">COMPLETE</option>
                  <option value="cancelled">CANCELLED</option>
                  <option value="failed">FAILED</option>
                </Select>
              </div>
              <div className="flex-1 p-2 pt-0 flex justify-end items-end">
                <Button
                  isDisabled={isFilterLoading}
                  onClick={() => handleClear()}
                  className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Clear Search
                </Button>
                <Button
                  isDisabled={isFilterLoading}
                  onClick={() => handleSearchSubmit()}
                  className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
                >
                  Seach Transactions
                </Button>
              </div>
            </>
          }
        </div>
      </Box>
    );
  };

export default PayoutSearch;
