/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, Button, Select } from "@chakra-ui/react";
import InputField from "components/fields/InputField";

interface UserTableSearchProps {
  initialFilters: any;
  filters: any;
  setFilters: any;
  onSearch: () => void;
  onClear: () => void;
  isLoading: boolean;
  isConfirming: boolean;
}

const UserTableSearch: React.FC<UserTableSearchProps> = ({
  initialFilters,
  filters,
  setFilters,
  onSearch,
  onClear,
  isLoading,
  isConfirming
}) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    onSearch();
  };

  const handleClear = () => {
    onClear();
  };

  return (
    <Box
      className="mt-6 mb-3 flex flex-wrap items-center card card-pad"
    >
      <div className="flex w-full">
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Email
          </label>
          <InputField
            disabled={isLoading || isConfirming}
            variant="auth"
            extra="shadow-sm"
            label=""
            placeholder=""
            id="email"
            name="email"
            type="text"
            autoComplete="off"
            value={filters.email}
            onChange={handleChange}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Username
          </label>
          <InputField
            disabled={isLoading || isConfirming}
            variant="auth"
            extra="shadow-sm"
            label=""
            placeholder=""
            id="username"
            name="username"
            type="text"
            autoComplete="off"
            value={filters.username}
            onChange={handleChange}
          />
        </div>
        <div className="w-1/4 p-2 pt-0">
          <label className="mb-1 text-sm text-navy-700 dark:text-white font-bold">
            Status
          </label>
          <Select
            isDisabled={isLoading || isConfirming}
            id="status"
            name="status"
            value={filters.status}
            onChange={handleSelectChange}
            className="text-navy-700 mt-2 font-bold text-sm h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full shadow-sm"
          >
            <option value="">All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </Select>
        </div>
        <div className="flex-1 p-2 pt-0 flex justify-end items-end">
          <Button
            isDisabled={isLoading || isConfirming}
            onClick={handleClear}
            variant="solid"
            size="sm"
            className="mr-3 rounded-md bg-gray-100 px-3 py-2.5 text-sm font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
          >
            Clear Search
          </Button>
          <Button
            isDisabled={isLoading || isConfirming}
            onClick={handleSearch}
            variant="solid"
            size="sm"
            className="rounded-md px-3 py-2.5 text-sm transition duration-200 btn-primary"
          >
            Search Users
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default UserTableSearch;
