/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ReactElement } from "react";
import { Badge } from '@chakra-ui/react'
import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridClasses } from "@mui/x-data-grid";
import axios from "axios";
import Card from "components/card";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ImSpinner8 } from "react-icons/im";
import { Tooltip, IconButton } from "@mui/material";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

interface PayloadType {
  [key: string]: any;
}

type Merchant = {
  id: string;
  name: string;
};

type Subaccount = {
  id: string;
  name: string;
};

type Receiver = {
  id: string;
  firs_tname: string;
  last_name: string;
  email: string;
};

type ReceiverAccount = {
  id: string;
  email: string;
  bsb: string;
  bank_code: string;
  account_number: string;
};

type PayoutProvider = {
  id: string;
  name: string;
};

type RowData = {
  id: string;
  status: string;
  amount: string;
  asset: string;
  created_at: string;
  note: string;
  merchant: Merchant;
  subaccount: Subaccount;
  transactions: any[];
  receiver: Receiver;
  receiver_account: ReceiverAccount;
  payout_provider: PayoutProvider;
  batch_id: string;
  cause: any;
};

const customDataGridStyles: Partial<GridClasses> = {
  root: 'mui-table-custom',
  columnHeader: 'text-start text-md font-bold',
  row: ''
};

const rowPerPage = 5;

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const [totalPayoutAmountPast30Days, setTotalPayoutAmountPast30Days] = useState<Number>(0);

  const subaccount_id = localStorage.getItem("subaccountId");
  const userRole = localStorage.getItem("userRole")?.toLowerCase();
  const columnMinWidth = userRole !== "superadmin" ? 350 : 250;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchPayouts({ page: page, limit: rowPerPage, subaccount_id: subaccount_id });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const fetchPayouts = async (payload: PayloadType = {}) => {
    try {

      const queryObject: PayloadType = {
        limit: payload?.limit,
        subaccount_id: payload?.subaccount_id,
        usertype: userRole === 'superadmin' ? "a" : "m"
      };

      if (userRole === 'superadmin') {
        delete queryObject.subaccount_id;
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/payout/get-all-payouts?${queryString}`
      );

      let newData = response.data.payouts?.payload;

      setTotalPayoutAmountPast30Days(response.data.payouts?.totalpayoutamount_last_thirtydays);

      setRowCount(newData.length);

      if (newData && newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  interface PayoutStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }


  const statusClasses = 'status';

  const statusList: PayoutStatusItems[] = [
    { status: "awaiting", value: 'awaiting', display: <Badge className={`${statusClasses} status-awaiting`}>Awaiting</Badge> },
    { status: "sent_mail", value: 'sent_mail', display: <Badge className={`${statusClasses} status-sent_mail`}>Email Sent</Badge> },
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "unassessed", value: 'unassessed', display: <Badge className={`${statusClasses} status-unassessed`}>Unassessed</Badge> },
    { status: "in_progress", value: 'in_progress', display: <Badge className={`${statusClasses} status-in_progress`}>In Progress</Badge> },
    { status: "successful", value: 'successful', display: <Badge className={`${statusClasses} status-successful`}>Completed</Badge> },
    { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
    { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
    { status: "successful_pending_kyc", value: 'successful_pending_kyc', display: <Badge className={`${statusClasses} status-successful_pending_kyc`}>Successful Pending KYC</Badge> }
  ];

  const columns: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "merchant",
      headerName: "Merchant",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.merchant.name,
    },
    {
      field: "subaccount",
      headerName: "Subaccount",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.subaccount.name,
    },
    {
      field: "amount", headerName: "Amount", sortable: false, headerAlign: 'right', align: 'right', flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        displayAmount(params.row.amount) + ' ' + params.row.asset
    },
    { field: "note", headerName: "Description", 
      flex: 1, sortable: false },
    {
      field: "status", headerName: "", headerAlign: 'right', align:'right', sortable: false, flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) => {
        const status = params.value as string;
        const statusItem = statusList.find(item => item.status === status);      
        return (
          <>
            {userRole === 'superadmin' && status === 'failed' && params.row.cause && params.row.cause.fail ? (
              <Tooltip title={params.row.cause.fail} arrow placement="top">
                {statusItem ? statusItem.display : null}
              </Tooltip>
            ) : (
              <>
                {statusItem ? statusItem.display : null}
              </>
            )}
          </>
        );
      }
    },
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY");
  };

  const filterColumns = () => {
    let rebuildColumns = [];
    if (userRole !== "superadmin") {
      const excludeFields = ["merchant", "subaccount"];
      rebuildColumns = columns.filter((column) => !!!excludeFields.includes(column?.field));
    } else {
      rebuildColumns = columns;
    }
    return rebuildColumns;
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      subaccount_id: subaccount_id,
    };

    // if select new row per page, reset page to 0, reset lastkey
    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        subaccount_id: subaccount_id,
      };
      setPage(0);
      setPageSize(newValue.pageSize);
    } else {
      setPage(newValue.page);
    }

    fetchPayouts(newFilters);
  };
  
  const displayAmount = (amount: any) => {
    if (amount === 0) {
      return "$0.00";
    }
    return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <Card className="mt-5 p-8 card h-30">
        <div className="flex flex-wrap">
          <h3 className="text-lg text-navy-700 font-bold dark:text-white">
            Total Payouts
          </h3>
        </div>
        <div className="flex flex-wrap mb-3">
          <h3 className="text-xs text-navy-700 dark:text-white">
            past 30 days
          </h3>
        </div>
        <div className="flex flex-wrap">
          {isLoading ? (
            <ImSpinner8 className="spinner text-green-500" style={{ fontSize: '2rem' }}/>
          ) : (
            <h3 className="text-2xl text-green-500 font-bold">{displayAmount(totalPayoutAmountPast30Days)}</h3>
          )}
        </div>
      </Card>
        <div className="flex flex-wrap mt-6 mb-5 pl-3">
          <h3 className="text-lg text-navy-700 font-bold dark:text-white">
            Latest Payouts 
          </h3>
        </div>
      <Box
        className="w-full card"
      >
          <DataGrid
            sx={{ 
              '& .MuiDataGrid-cell': {
                padding: '8px 16px',
              },
              '& .MuiDataGrid-columnHeader': {
                padding: '8px 16px',
              },
              "& .MuiTablePagination-input": {
                marginRight: "-20px"
              },
              "& .MuiTablePagination-displayedRows": {
                display: "none"
              },
            }} 
            loading={isLoading}
            rows={tableData || []}
            columns={filterColumns()}
            getRowId={(row) => row.id}
            rowCount={rowCount}
            sortingMode="server"
            paginationMode="server"
            pageSizeOptions={[10, 25, 50, 100]}
            rowHeight={48}
            columnHeaderHeight={48}
            paginationModel={{ page: 0, pageSize: rowPerPage }}
            onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            autoHeight
            classes={customDataGridStyles}
            hideFooter={true}
          />
      </Box>
    </div>
  );
};

export default Dashboard;
