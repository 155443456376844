/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@chakra-ui/react";
import Logo from "assets/img/logo-light.svg";
import Card from "components/card";
import { useAuth } from "./../../routes/hooks/useAuth";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

export default function SignIn() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { checkAuthStatus } = useAuth();

  useEffect(() => {

    document.title = "Payout Administration Panel";

    const checkUserState = async () => {
      const authStatus = await checkAuthStatus();
      if(authStatus) {
        localStorage.clear();

        sessionStorage.removeItem("attemptedRefresh");

        Auth.signOut();
      }
    };
    
    localStorage.removeItem("startIdleCheck");

    const startIdleCheckId = localStorage.getItem("startIdleCheckId");
    clearInterval(startIdleCheckId);
    localStorage.removeItem("startIdleCheckId");

    const startCountdownId = localStorage.getItem("startIdleCheckId");
    clearInterval(startCountdownId);
    localStorage.removeItem("startCountdownId");

    checkUserState();
  }, []);

  const navigate = useNavigate();
  const newPasswordError = !newPassword ? "" : "";

  // const isFormValid = !emailError && !passwordError;
  const isFormValid = email !== "" && password !== "";
  const isNewPasswordValid = !newPasswordError;

  const signInAndUpdate = async () => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const cognitoId = idToken.payload.sub;
    const userGroups = idToken.payload["cognito:groups"];
    
    const responseAuth = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/auth`,
      { email: email, cognitoId: cognitoId }
    );
    let { token, expires, status } = responseAuth.data;

    if (token != null) {
      localStorage.setItem("apiToken", token);
      localStorage.setItem("apiTokenExpires", expires);
      localStorage.setItem("lastSignIn", dayjs().tz(tz).format());
    }

    if (status === "Inactive") {
      setError("Your account is inactive. Please contact Admin.");
      setIsLoading(false);
      return;
    }

    if (userGroups === undefined) {
      setError("User group does not exist. Please contact Admin.");
      setIsLoading(false);
      return;
    }

    const userRoles = responseAuth.data.userRoles;

    if (userRoles.length === 1) {
      const { role, merchantId, subaccountId } = userRoles[0];
      localStorage.setItem("userRole", role);
      localStorage.setItem("merchantId", merchantId);
      localStorage.setItem("subaccountId", subaccountId);
      localStorage.setItem("merchantsCount", '1');
      localStorage.setItem("subaccountsCount", '1');

      if(role.toLowerCase() !== 'superadmin') {
        const subaccountInfo = await fetchSubaccountById(subaccountId, token);
        localStorage.setItem("subaccountName", subaccountInfo.suba_name);
      } else {
        localStorage.setItem("subaccountName", '');
      }

      navigate(`/${role.toLowerCase()}/default`);
    } else {
      const merchantIds: any = Array.from(
        new Set(userRoles.map((role: any) => role.merchantId))
      );

      if (merchantIds.length === 1) {
        const merchantId: string = merchantIds[0];
        localStorage.setItem("merchantId", merchantId);
        localStorage.setItem("merchantsCount", '1');

        const subaccountIds: any = Array.from(
          new Set(
            userRoles
              .filter((role: any) => role.merchantId === merchantId)
              .map((role: any) => role.subaccountId)
          )
        );

        if (subaccountIds.length === 1) {
          const subaccountId: string = subaccountIds[0];
          const selectedRole = userRoles.find(
            (role: any) =>
              role.merchantId === merchantId &&
              role.subaccountId === subaccountId
          );
          if (selectedRole) {
            localStorage.setItem("userRole", selectedRole.role);
            localStorage.setItem("subaccountId", subaccountId);
            localStorage.setItem("subaccountsCount", '1');
            navigate(`/${selectedRole.role.toLowerCase()}/default`);
          }
        } else {
          localStorage.setItem("userEmail", email);
          localStorage.setItem("subaccountsCount", subaccountIds.length);
          navigate("/auth/subaccount-selection", {
            state: { merchantId, cognitoId, userRole: userRoles },
          });
        }
      } else {
        localStorage.setItem("userEmail", email);
        localStorage.setItem("merchantsCount", merchantIds.length);
        navigate("/auth/merchant-selection", {
          state: { cognitoId, userRole: userRoles },
        });
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setError(null);
    if (isFormValid) {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(email, password);
        if (user.challengeName !== "NEW_PASSWORD_REQUIRED") {
          await signInAndUpdate();
        } else {
          setIsLoading(false);
          setIsFirstLogin(true);
        }
      } catch (error: any) {
        console.error("Error signing in", error);
        setError(error.message);
        setIsLoading(false);
      }
    }
  };

  const handleNewPasswordSubmit = async () => {
    setError(null);
    if (isNewPasswordValid) {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(email, password);
        await Auth.completeNewPassword(user, newPassword);
        await signInAndUpdate();
      } catch (error: any) {
        console.error("Error setting new password", error);
        setError(error.message);
        setIsLoading(false);
      }
    }
  };

  const fetchSubaccountById = async (id: string, token: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subaccount/get-subaccount-by-id/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      return response.data.subaccounts.payload;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  return Logo ? (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-[420px] flex-col items-center md:pl-4 lg:pl-0">
        <div className="mb-12 flex items-center justify-center">
          <img src={Logo} alt="Payswiftly"  width="229px"/>
        </div>
        <Card
          className="bg-white p-8 sm:rounded-md  shadow-cs"
        >
          <h4 className="text-2xl font-bold mb-2 text-navy-700">
            Welcome
          </h4>
          <p className="mb-8 text-base text-gray-600">
            Enter your login credentials to continue
          </p>

          <InputField
            disabled={isLoading}
            variant="auth"
            extra="mb-6"
            label="Email"
            placeholder=""
            id="email"
            type="text"
            autoComplete="off"
            value={email}
            readonly={isFirstLogin}
            onChange={(e) => setEmail(e.target.value)}
            // error={emailError}
          />

          <InputField
            disabled={isLoading}
            variant="auth"
            extra="mb-3"
            label="Password"
            placeholder=""
            id="password"
            type="password"
            value={password}
            readonly={isFirstLogin}
            onChange={(e) => setPassword(e.target.value)}
            // error={passwordError}
          />

          {isFirstLogin && (
            <InputField
              disabled={isLoading}
              variant="auth"
              extra="mb-3"
              label="New Password"
              placeholder="New password"
              id="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={newPasswordError}
            />
          )}

          {error && (
            <p className="flex justify-center text-sm text-red-500">{error}</p>
          )}

          {isFirstLogin ? (
            <Button
              onClick={handleNewPasswordSubmit}
              isDisabled={!isNewPasswordValid || isLoading}
              isLoading={isLoading}
              loadingText="Please wait..."
              className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
            >
              SET YOUR NEW PASSWORD
            </Button>
          ) : (
            <Button
              onClick={handleSubmit}
              isDisabled={!isFormValid || isLoading}
              isLoading={isLoading}
              loadingText="Please wait..."
              className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:hover:bg-green-300 dark:active:bg-green-200"
            >
              Sign In
            </Button>
          )}
        </Card>
      </div>
    </div>
  ) : null;
}
