/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import ReceiverList from "./components/ReceiverList";
import ReceiverSearch from "./components/ReceiverSearch";
import { FaPlus } from "react-icons/fa";
import { Button } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";

interface Filters {
  [key: string]: any;
}

const rowPerPage = 10;

const initialFilters: Filters = {
  field: "",
  keyword: "",
  status: "",
  kyc_status: "",
  merchant_id: "",
  subaccount_id: "",
};

interface PayloadType {
  [key: string]: any;
}

const Receiver = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const subaccount_id = localStorage.getItem("subaccountId");
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const [merchantList, setMerchantList] = useState<any>(null);
  const [subaccountList, setSubaccountList] = useState<any>(null);
  const [isSubaccountListLoading, setIsSubaccountListLoading] = useState<boolean>(false);
  const [merchantIdToGetSubaccount, setMerchantIdToGetSubaccount] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {      
      localStorage.removeItem("merchantIdToFilterReceiver");
      localStorage.removeItem("subaccountIdToFilterReceiver");
      setIsLoading(true);
      await fetchReceivers({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
      if (userRole === 'superadmin') {
        await fetchMerchants();
      }
      setIsLoading(false);
      setIsFilterLoading(false);
      setIsSubaccountListLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      fetchSubaccounts();
    };

    fetchData();
  }, [merchantIdToGetSubaccount]);

  const fetchReceivers = async (payload: PayloadType = {}) => {
    try {
      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        subaccount_id: subaccount_id
      };

      if (payload?.field !== "" && payload?.field !== undefined && payload?.keyword !== "" && payload?.keyword !== undefined) {
        queryObject[payload?.field] = payload?.keyword
      }

      if (payload?.status !== "" && payload?.status !== undefined) {
        queryObject.status = payload?.status
      }

      if (payload?.kyc_status !== "" && payload?.kyc_status !== undefined) {
        queryObject.kyc_status = payload?.kyc_status
      }

      if (userRole === 'superadmin') {
        delete queryObject.subaccount_id;

        const merchantId = localStorage.getItem("merchantIdToFilterReceiver");
        const subaccountId = localStorage.getItem("subaccountIdToFilterReceiver");

        if (merchantId && merchantId !== "" && merchantId !== undefined) {
          queryObject.merchant_id = merchantId
        }

        if (subaccountId && subaccountId !== "" && subaccountId !== undefined) {
          queryObject.subaccount_id = subaccountId
        }
      }

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/receiver/get-receivers?${queryString}`
      );

      let newData = response.data.receivers?.payload;

      if (newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }

      setIsLoading(false);

      // Manage Last Key and Total Record
      let newLastKey = response.data.receivers?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
        // console.log("newPage", newPage);
        // console.log("newPageSize", newPageSize);
        // console.log("setRowCount", (newPage + 1) * newPageSize + newPageSize);
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      // Clear all
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      subaccount_id: subaccount_id,
      ...filters
    };

    // If click prev pagination
    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    // if select new row per page, reset page to 0, reset lastkey
    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        subaccount_id: subaccount_id,
        ...filters
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchReceivers(newFilters);
  };

  const handleClickCreate = () => {
    navigate(`/${userRole}/receiver/create`);
  };

  const handleSearchSubmit = () => {
    setIsLoading(true);
    setIsFilterLoading(true);
    setCurrentLastKey("");
    fetchReceivers({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...filters });
    setIsFilterLoading(false);
  };

  const handleSearchClear = () => {
    handleResetTable();
  };

  const handleResetTable = async () => {
    localStorage.removeItem("merchantIdToFilterReceiver");
    localStorage.removeItem("subaccountIdToFilterReceiver");
    setIsLoading(true);
    setIsFilterLoading(true);
    setFilters(initialFilters);
    setLastKeys([]);
    setCurrentLastKey("");
    setSubaccountList(null);
    setMerchantIdToGetSubaccount(null);
    setPage(0)
    await fetchReceivers({ page: 0, limit: rowPerPage, lastkey: "", subaccount_id: subaccount_id, ...initialFilters });
    setIsFilterLoading(false);
  };

  const fetchMerchants = async () => {
    try {
      let requestUrl = `/api/merchant/get-all-merchants`;

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        requestUrl
      );

      const data = response.data.merchants?.payload;

      setMerchantList(data);
    } catch (error) {
      console.error("Error fetching merchants", error);
    }
  };

  const fetchSubaccounts = async () => {
    if (merchantIdToGetSubaccount !== null) {
      try {
        setIsSubaccountListLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
          `/api/subaccount/get-all-subaccounts-for-merchant?merchantId=${merchantIdToGetSubaccount}`
        );

        const data = response.data.subaccounts?.payload;

        setSubaccountList(data);
        setIsSubaccountListLoading(false);
      } catch (error) {
        console.error("Error fetching subaccounts", error);
      }
    }
  };

  return (
    <>
      {(userRole === 'admin' || userRole === 'merchant') &&
        <div className="flex justify-start mt-6 mb-3">
          <Button
            onClick={() => handleClickCreate()}
            className="btn btn-action"
          >
            <FaPlus className="mr-2" />Create New Receiver
          </Button>
        </div>
      }
      <ReceiverSearch
        isLoading={isLoading}
        filters={filters}
        setFilters={setFilters}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchClear={handleSearchClear}
        isFilterLoading={isFilterLoading}
        merchantList={merchantList}
        subaccountList={subaccountList}
        isSubaccountListLoading={isSubaccountListLoading}
        merchantIdToGetSubaccount={merchantIdToGetSubaccount}
        setMerchantIdToGetSubaccount={setMerchantIdToGetSubaccount}
      />
      <ReceiverList
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        tableData={isLoading ? [] : tableData}
        setPaginationModel={setPaginationModel}
        currentLastKey={currentLastKey}
      />
    </>
  );
};

export default Receiver;
