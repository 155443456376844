/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import axios from "axios";
import { ImSpinner8 } from "react-icons/im";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate, useParams } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export default function AccountConfirmation() {
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [contentTemplate, setContentTemplate] = useState<string>("");

  const [accountData, setAccountData] = useState<any>(null);
  const navigate = useNavigate();
  const { token } = useParams();
  // const htmlStringWaiting = `<div class="items-center flex-col lg:pl-0 max-w-[520px] md:pl-4 mt-[10vh] w-full"><div class="items-center flex justify-center mb-8"><img alt=Payswiftly src={{logo}} width=229px></div><div class="bg-white p-8 shadow-3xl sm:rounded-md"><h4 class="mb-3 dark:text-white font-bold text-2xl text-center text-navy-700">Account Information Confirmation</h4><hr class=mb-6><div class="dark:text-white text-base text-navy-700"><div class=mb-3>To ensure your payout from {{company}} is processed correctly, please review and confirm your bank account details below.</div><div class=mb-3><span class=font-bold>First Name:</span> {{firstname}}</div><div class=mb-3><span class=font-bold>Middle Name:</span> {{middlename}}</div><div class=mb-3><span class=font-bold>Last Name:</span> {{lastname}}</div><div class=mb-3><span class=font-bold>Email:</span> {{email}}</div><div class=mb-3><span class=font-bold>BSB:</span> {{bsb}}</div><div class=mb-3><span class=font-bold>Account No.:</span> {{account_no}}</div></div><button class="mb-3 dark:text-white active:bg-green-700 bg-green-500 chakra-button css-4xx2wk dark:active:bg-green-200 dark:bg-green-400 dark:hover:bg-green-300 duration-200 font-medium hover:bg-green-600 linear py-[12px] rounded-xl text-base text-white transition w-full"id=confirm-btn type=button>Yes, I confirm these details are correct</button><div class="dark:text-white text-base text-navy-700"><div class=mb-3>By confirming, you acknowledge that these are your correct bank details, and your payout from {{company}} will be sent to the account listed.</div><div class=mb-3>If you notice any discrepancies, please contact our support team immediately at <a class="text-green-500 underline"href=mailto:{{support_email}}>{{support_email}}</a> before proceeding.</div></div></div></div>`;
  // const htmlStringConfirmed = `<div class="items-center flex-col lg:pl-0 max-w-[520px] md:pl-4 mt-[10vh] w-full"><div class="items-center flex justify-center mb-8"><img alt=Payswiftly src={{logo}} width=229px></div><div class="bg-white p-8 shadow-3xl sm:rounded-md"><h4 class="mb-3 dark:text-white font-bold text-2xl text-center text-navy-700">Account Information Confirmed</h4><hr class=mb-6><div class="text-base dark:text-white text-navy-700"><div class=mb-3>You have successfully confirmed that your account details are correct. Nothing more needs to be done and you will be receiving your payment from {{company}} Pty Ltd shortly.</div><div class=mb-3><span class=font-bold>First Name:</span> {{firstname}}</div><div class=mb-3><span class=font-bold>Middle Name:</span> {{middlename}}</div><div class=mb-3><span class=font-bold>Last Name:</span> {{lastname}}</div><div class=mb-3><span class=font-bold>Email:</span> {{email}}</div><div class=mb-3><span class=font-bold>BSB:</span> {{bsb}}</div><div class=mb-3><span class=font-bold>Account No.:</span> {{account_no}}</div></div><div class="justify-center mt-6 text-center text-green-500">You can now close this window</div></div></div>`;

  useEffect(() => {
    const initialize = async () => {
      document.title = "Account Confirmation";

      const element = document.querySelector('.relative.float-right.h-full.min-h-screen.w-full.bg-darkPrimary');
      if (element) {
          element.classList.replace('bg-darkPrimary', 'bg-white');
      }

      await validateAccountByToken();
    };

    initialize();

    const handleClickConfirm = () => {
      handleSubmit();
    };

    const observer = new MutationObserver(() => {
      const element = document.getElementById('confirm-btn');
      if (element) {
        element.addEventListener('click', handleClickConfirm);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const validateAccountByToken = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/receiveraccount/validate`,
        { confirm_reference: token }
      );

      if (response.data.status === 'ok') {
        if (response.data.data.receiver_account.confirm_status !== 'awaiting') {
          setContentTemplate(response.data.data.html_snippet);
          setIsValidating(false);
          setAccountData(response.data.data);
        } else {
          navigateToNotFoundPage();
        }
      } else {
        navigateToNotFoundPage();
      }
    } catch (error) {
      navigateToNotFoundPage();
    }
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/receiveraccount/confirm`,
        { confirm_reference: token }
      );

      if (response.data.status === 'ok') {
        setIsProcessing(false);
        setContentTemplate(response.data.html_snippet);
      } else {
        setIsProcessing(false);
        navigateToErrorPage();
      }
    } catch (error) {
      navigateToErrorPage();
    }
  };

  const navigateToNotFoundPage = async () => {
    navigate(`/404`);
  };

  const navigateToErrorPage = async () => {
    navigate(`/500`);
  };

  return (
    <>
      {(isValidating || isProcessing || accountData === null) &&
        <div className="flex items-center justify-center h-screen">
          <ImSpinner8 className="spinner" style={{ fontSize: '2rem' }} />
        </div>
      }
      {!isValidating && !isProcessing && accountData !== null &&
        <div className="mb-auto flex flex-col items-center justify-center md:pr-0 lg:pl-0 xl:max-w-full">
          <div className="mb-16 mt-4 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
            <div dangerouslySetInnerHTML={{ __html: contentTemplate }} />
          </div>
        </div>
      }
    </>
  );
}
