// App.tsx
// import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "layouts/auth";
import ProtectedRoute from "routes/ProtectedRoute";

const App = () => {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/*" element={<ProtectedRoute />} />
    </Routes>
  );
};

export default App;
