import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import { Button, Select } from '@chakra-ui/react';
import { ImCogs } from "react-icons/im";

const BatchGenerateFileConfirmModal: React.FC<{
    batchToGenerateFile: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
    setSwalProps: any;
}> = ({ isOpen, onClose, triggerReload, batchToGenerateFile, setSwalProps }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [bankFormat, setBankFormat] = useState<string>("");
    const userRole = localStorage.getItem("userRole")?.toLowerCase();

    const isFormValid = bankFormat !== "" ? true : false;

    const closeModal = async () => {
        onClose();
    };

    const handleInputChange = async (event: any) => {
        const { value } = event.target;
        setBankFormat(value);
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const postData = {
                batch_id: batchToGenerateFile.id,
                bank_format: bankFormat
            }

            await axios.post(process.env.REACT_APP_API_URL + `/api/batch/generate-file`, postData)
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        onClose();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "The file has been generated",
                            showConfirmButton: true,
                            didClose: () => {
                                triggerReload();
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }

        setBankFormat("");
    };

    const displayAmount = (amount: any) => {
        if (amount === 0) {
            return "$0.00";
        }
        return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {batchToGenerateFile &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody>
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Generate a new batch file?</h1>
                                <div className="mb-[20px]">
                                    <div className="mb-8">
                                        You can generate a new file until the batch is set to "IN PROGRESS." Generating a new file will replace the current one.
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Type:</span> {batchToGenerateFile.batch_type}
                                    </div>
                                    {userRole === 'superadmin' &&
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">File:</span>
                                            {batchToGenerateFile.file_bucket !== null && batchToGenerateFile.file_key !== null ?
                                                <>
                                                    {batchToGenerateFile.file_name}
                                                </>
                                                :
                                                "-"
                                            }
                                        </div>
                                    }
                                    {userRole !== 'superadmin' &&
                                        <div className="mb-1">
                                            <span className="mr-1 font-bold">Reference:</span> {batchToGenerateFile.batch_reference}
                                        </div>
                                    }
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Record(s):</span> {batchToGenerateFile.record}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Amount:</span> {displayAmount(batchToGenerateFile.amount)} {batchToGenerateFile.asset}
                                    </div>
                                    <div className="flex justify-center"><hr className="mb-3 mt-4 w-full" /></div>
                                    <span className="mr-1 font-bold">File Processor</span>
                                    <Select isDisabled={isProcessing} id="bank_format" value={bankFormat} onChange={handleInputChange} className="h-10  mt-2 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 w-full shadow-sm font-bold text-navy-700 text-sm">
                                        <option value="">Please Select</option>
                                        {batchToGenerateFile.bank_format.map((bank: any) => (
                                            <option key={bank.name} value={bank.name}>{bank.description} ({bank.function})</option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isDisabled={!isFormValid}
                                        isLoading={isProcessing}
                                        loadingText='Generating...'
                                        className="btn btn-primary"
                                    >
                                        <ImCogs className="h-4 w-4" />&nbsp;Generate
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </>
    );
};

export default BatchGenerateFileConfirmModal;
