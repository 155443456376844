/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import { Select, Button } from '@chakra-ui/react';
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ReceiverDetailTab: React.FC<{
  detail: any;
  setSwalProps: any;
  countries: any;
  dialcodeList: any;
}> = ({ detail, setSwalProps, countries, dialcodeList }) => {

  const [receiverDetailForEdit, setReceiverDetailForEdit] = useState(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    setReceiverDetailForEdit(detail);
  }, [detail]);

  const handleInputChange = async (event: any) => {
    const { id, value } = event.target;
    setReceiverDetailForEdit({ ...receiverDetailForEdit, [id]: value });
  };

  const isFormValid = receiverDetailForEdit !== null ? (
    receiverDetailForEdit.first_name
    && receiverDetailForEdit.last_name
    && receiverDetailForEdit.email
    && receiverDetailForEdit.dob
    && receiverDetailForEdit.dialcode
    && receiverDetailForEdit.phone
    && receiverDetailForEdit.address
    && receiverDetailForEdit.city
    && receiverDetailForEdit.state
    && receiverDetailForEdit.postcode
    && receiverDetailForEdit.country
  ) : false;
  const handleSubmit = async () => {
    if (isFormValid) {
      let receiverDetailData = JSON.parse(JSON.stringify(receiverDetailForEdit));

      delete receiverDetailData['id'];
      delete receiverDetailData['account'];
      delete receiverDetailData['created_at'];
      delete receiverDetailData['kyc_status'];
      delete receiverDetailData['status'];
      delete receiverDetailData['applicant_id'];

      try {
        setIsUpdating(true);
        await axios.put(
          process.env.REACT_APP_API_URL + `/api/receiver/update/${receiverDetailForEdit.id}`,
          receiverDetailData
        )
          .then(function (response) {
            if (response && response.data?.status === 'ok') {
              setSwalProps({
                show: true,
                icon: "success",
                title: "Success",
                html: "Receiver has been successfully updated",
                showConfirmButton: false,
                timer: 2000,
                didClose: () => {
                  setSwalProps({});
                  setIsUpdating(false);
                },
              });
            } else {
              setIsUpdating(false);
              setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: response.data.message,
                showConfirmButton: true,
                didClose: () => {
                  setSwalProps({});
                  setIsUpdating(false);
                },
              });
            }
          })
          .catch(function (error) {
            setIsUpdating(false);
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: error.response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
                setIsUpdating(false);
              },
            });
          });
      } catch (error) {
        setIsUpdating(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: "Oops!",
          html: "Something went wrong, please try again later.",
          showConfirmButton: true,
          didClose: () => {
            setSwalProps({});
            setIsUpdating(false);
          },
        });
      }
    }
  };

  const preventTyping = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow Ctrl/Command + C (Copy)
    if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
      return; // Allow copy
    }
    
    // Prevent all other key presses
    e.preventDefault();
  };

  return (
    <>
      {receiverDetailForEdit !== null && countries !== null && dialcodeList !== null ? (
        <>
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/2 md:pr-4">
              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="First Name *"
                placeholder=""
                id="first_name"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.first_name}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Middle Name"
                placeholder=""
                id="middle_name"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.middle_name}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Last Name *"
                placeholder=""
                id="last_name"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.last_name}
                onChange={handleInputChange}
              />

              <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                Gender
              </label>

              <Select disabled={isUpdating} id="gender" value={receiverDetailForEdit.gender} onChange={handleInputChange} className="h-10 rounded-xl custom-select relative bg-white border border-gray-200 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                <option value="">-- Please Select --</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </Select>

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Email *"
                placeholder=""
                id="email"
                type="email"
                autoComplete="off"
                value={receiverDetailForEdit.email}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Date of Birth *"
                placeholder=""
                id="dob"
                type="date"
                autoComplete="off"
                value={receiverDetailForEdit.dob}
                onChange={handleInputChange}
              />

              <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                Dialcode *
              </label>

              <Select disabled={isUpdating} id="dialcode" value={receiverDetailForEdit.dialcode} onChange={handleInputChange} className="h-10 rounded-xl custom-select relative bg-white border border-gray-200 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                <option value="">-- Please Select --</option>
                {Object.entries(dialcodeList).map(([key, value]: [any, any]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Phone *"
                placeholder=""
                id="phone"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.phone}
                onChange={handleInputChange}
              />
            </div>

            <div className="w-full md:w-1/2 md:pl-4">
              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Address *"
                placeholder=""
                id="address"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.address}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Address 2"
                placeholder=""
                id="address2"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.address2}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="City *"
                placeholder=""
                id="city"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.city}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="State *"
                placeholder=""
                id="state"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.state}
                onChange={handleInputChange}
              />

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Postcode *"
                placeholder=""
                id="postcode"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.postcode}
                onChange={handleInputChange}
              />

              <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                Country *
              </label>

              <Select disabled={isUpdating} id="country" value={receiverDetailForEdit.country} onChange={handleInputChange} className="h-10 rounded-xl custom-select relative bg-white border border-gray-200 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                <option value="">-- Please Select --</option>
                {countries.map((item: any) => (
                  <option key={item.id} value={item.country_code_3}>
                    {item.country_name}
                  </option>
                ))}
              </Select>

              <InputField
                disabled={isUpdating}
                variant="auth"
                extra="mb-3 shadow-sm"
                label="Reference"
                placeholder=""
                id="reference"
                type="text"
                autoComplete="off"
                value={receiverDetailForEdit.reference}
                onChange={handleInputChange}
              />

              {receiverDetailForEdit.applicant_id &&
                <InputField
                  variant="auth"
                  extra="mb-3 shadow-sm"
                  label="Sumsub Applicant ID"
                  labelAsLink={`https://cockpit.sumsub.com/checkus#/applicant/${receiverDetailForEdit.applicant_id}`}
                  placeholder=""
                  id="sumsub_link"
                  type="text"
                  autoComplete="off"
                  value={receiverDetailForEdit.applicant_id}
                  onChange={handleInputChange}
                  onKeyDown={preventTyping}
                />
              }
            </div>
          </div>
          <div className="flex flex-wrap justify-end">
            <div className="mt-4">
              <Button
                isDisabled={isUpdating}
                onClick={() => navigate(`/${userRole}/receiver`)}
                className="mr-3 btn btn-default"
              >
                <FaAngleLeft />&nbsp;Return to Receivers
              </Button>
              &nbsp;
              <Button
                onClick={() => handleSubmit()}
                isDisabled={!isFormValid || isUpdating}
                isLoading={isUpdating}
                loadingText='Updating Receiver...'
                className="btn btn-primary"
              >
                Update Receiver
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
        </>
      )}
    </>
  );
};

export default ReceiverDetailTab;
