import React, { useEffect, useState, ReactElement } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { Button } from '@chakra-ui/react';

const UpdateReceiverAccountModal: React.FC<{
    setSwalProps: any;
    receiverAccountDetail: any;
    isOpen: boolean;
    onClose(): void;
    triggerReloadReceiverAccountList(): void;
}> = ({ setSwalProps, isOpen, onClose, receiverAccountDetail, triggerReloadReceiverAccountList }) => {

    const initialData: {
        bsb: string;
        account_number: string;
    } = {
        bsb: receiverAccountDetail.bank_account_data.bsb,
        account_number: receiverAccountDetail.bank_account_data.account_number
    };

    const [receiverAccountDetailForUpdate, setReceiverAccountDetailForUpdate] = useState<any>(initialData);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>("");

    const handleInputChange = async (event: any) => {
        const { id, value } = event.target;
        setReceiverAccountDetailForUpdate({ ...receiverAccountDetailForUpdate, [id]: value });
    };

    const closeModal = async () => {
        onClose();
        setErrorMessage("");
        setReceiverAccountDetailForUpdate(null);
    };    

    const isFormValid = receiverAccountDetailForUpdate !== null ? (
        receiverAccountDetailForUpdate.bsb
        && receiverAccountDetailForUpdate.account_number
    ) : false;

    const handleSubmit = async () => {
        setErrorMessage("");
        if (isFormValid) {
            try {
                setIsUpdating(true);

                const dataToUpdate = {
                    bank_account_data: {                        
                        bsb: receiverAccountDetailForUpdate.bsb,
                        account_number: receiverAccountDetailForUpdate.account_number,
                    }
                };

                await axios.put(process.env.REACT_APP_API_URL + `/api/receiveraccount/update/${receiverAccountDetail.id}`, dataToUpdate)
                    .then(function (response) {
                        if (response && response.data?.status === 'ok') {
                            onClose();
                            setSwalProps({
                                show: true,
                                icon: "success",
                                title: "Success",
                                html: "Receiver account has been updated successfully",
                                showConfirmButton: true,
                                didClose: () => {
                                    setSwalProps({});
                                    setIsUpdating(false);
                                    setReceiverAccountDetailForUpdate(null);
                                    triggerReloadReceiverAccountList();
                                },
                            });
                        } else {
                            setIsUpdating(false);
                            setErrorMessage('Update receiver account has been failed');
                        }
                    })
                    .catch(function (error) {
                        setIsUpdating(false);
                        if (error.response) {
                            setErrorMessage(error.response.data.message);
                        } else {
                            setErrorMessage('Create receiver account has been failed');
                        }
                    });
            } catch (error: any) {
                setIsUpdating(false);
                setSwalProps({
                    show: true,
                    icon: "error",
                    title: "Oops!",
                    html: "Something went wrong, please try again later.",
                    showConfirmButton: true,
                    didClose: () => {
                        setSwalProps({});
                        setIsUpdating(false);
                    },
                });
            }
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">Update Account</h1>
                            <div className="mb-[20px]">
                                <InputField
                                    disabled={isUpdating}
                                    variant="auth"
                                    extra="mb-3 shadow-sm"
                                    label="BSB *"
                                    placeholder=""
                                    id="bsb"
                                    type="text"
                                    autoComplete="off"
                                    value={receiverAccountDetailForUpdate.bsb}
                                    onChange={handleInputChange}
                                />

                                <InputField
                                    disabled={isUpdating}
                                    variant="auth"
                                    extra="mb-3 shadow-sm"
                                    label="Account No. *"
                                    placeholder=""
                                    id="account_number"
                                    type="text"
                                    autoComplete="off"
                                    value={receiverAccountDetailForUpdate.account_number}
                                    onChange={handleInputChange}
                                />

                                <p className="text-sm text-red-500">{errorMessage}</p>
                            </div>
                            <div className="flex gap-2 justify-end">
                                <Button
                                    onClick={() => closeModal()}
                                    isDisabled={isUpdating}
                                    className="btn btn-default"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleSubmit()}
                                    isDisabled={!isFormValid}
                                    isLoading={isUpdating}
                                    loadingText='Updating Account...'
                                    className="btn btn-primary"
                                >
                                    Update Account
                                </Button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UpdateReceiverAccountModal;