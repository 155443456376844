import React, { ReactElement } from "react";
import moment from "moment";
import { Box } from "@mui/material";
import { Button, Badge } from '@chakra-ui/react'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { AiOutlineFileSearch } from "react-icons/ai";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

// type Balances = {
//   pending: string;
//   available: string;
// };

type Merchant = {
  id: string;
  name: string;
};

type Balances = {
  available: string;
};

type RowData = {
  id: string;
  merchant: Merchant;
  suba_name: string;
  balances: Balances;
};

const AccountList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
}> = ({ isLoading, tableData, page, pageSize, rowCount, setPaginationModel, currentLastKey }) => {

  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  const columns: GridColDef[] = [
    {
      field: "merchant",
      headerName: "Merchant",
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.merchant.name,
    },
    { field: "suba_name", headerName: "Subaccount", minWidth: 150, sortable: false, flex: 1 },
    {
      field: "balances",
      headerName: "Balance",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        displayAmount(params.row.balances.available),
    },
    {
      field: "actions", headerName: "", sortable: false, flex: 1,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        return (
          <Tooltip title="View More Details" arrow placement="right">
            <div className="mr-5">
              <IconButton
                id={"button-detail-" + params?.row?.id}
                onClick={() => handleClickDetail(params.row.id)}
              >
                <AiOutlineFileSearch className="h-5 w-5 text-navy-700" />
              </IconButton>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  interface CustomerStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusList: CustomerStatusItems[] = [
    { status: "pending", value: 'pending', display: <Badge className="rounded-lg bg-blue-100 px-2 py-1 text-xs font-semibold transition duration-200 text-blue-500">PENDING</Badge> },
    { status: "active", value: 'active', display: <Badge className="rounded-lg bg-green-100 px-2 py-1 text-xs font-semibold text-green-500 transition duration-200 dark:bg-green-400">ACTIVE</Badge> },
    { status: "completed", value: 'completed', display: <Badge className="rounded-lg bg-green-100 px-2 py-1 text-xs font-semibold text-green-500 transition duration-200 dark:bg-green-400">COMPLETED</Badge> }
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
  };

  const handleClickDetail = (id: any) => {
    navigate(`/${userRole}/account-management/${id}`);
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: 'mui-table-custom',
    columnHeader: 'text-start font-bold text-gray-600 uppercase',
    row: 'font-bold text-navy-700 dark:text-white mui-row-no-border'
  };

  const displayAmount = (amount: any) => {
    if (amount === 0) {
      return "$0.00";
    }
    return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <div className="flex flex-wrap mt-6 pb-4 pl-3">
        <h3 className="text-lg text-navy-700 font-bold dark:text-white">
          Accounts
        </h3>
      </div>
      <Box
        className="w-full card"
      >
        <DataGrid
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '8px 16px',
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px"
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none"
            },
          }}
          loading={isLoading}
          rows={tableData || []}
          columns={columns}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={48}
          columnHeaderHeight={48}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>
    </div>
  );
};

export default AccountList;
